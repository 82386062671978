import React from 'react';
import { Container, Typography, Box, Grid, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import NavBar from '../NavBar/NavBar';
import Footer from './Footer';
import aditPic from '../../images/aditPic.png';
import './styles.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Icon for bullet points

const RCP = () => {
  return (
    <div style={{ background: 'linear-gradient(280deg, #9c27b0 0%, #000000 30%)', minHeight: '100vh', color: "white" }}>
        <NavBar/>
        <Container maxWidth="lg" sx={{ py: 8, mt:9}}>
            <Typography variant="h2" gutterBottom align="center" className='neon-purple'>
               Refund and Cancellation Policy
            </Typography>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                Cancellations
                </Typography>
                <Typography paragraph>
                Users cannot cancel their reservations. All sales are final. Please look at exceptional circumstances for more information. </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                Refunds
                </Typography>
                <Typography paragraph>
                Nightly is committed to ensuring a seamless booking and nightlife experience. However, we generally do not offer refunds for cancellations or no-shows. All sales are final upon booking. We encourage our users to review their selections carefully before confirming their reservations.
                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                Exceptional Circumstances
                </Typography>
                <Typography paragraph>
                We understand that sometimes extraordinary situations occur. If you believe your case warrants special consideration, please contact us. While we cannot guarantee a refund, we will review each request on a case-by-case basis. Decisions to override our no-refund policy are at Nightly's sole discretion and are final.                              </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Contact Us
                </Typography>
                <Typography paragraph>
                For more information about our cancellation and refund policy or to submit a request for special consideration, please contact us.</Typography>
            </Box>
        </Container>
        <Footer/>
    </div>
  );
};

export default RCP;
