import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for navigation
import NavBar from '../NavBar/NavBar';
import Search from './Search';
import Footer from '../Footer/Footer';
import { Typography, Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import bannerImage1 from "../../images/banner1.png"
import bannerImage2 from "../../images/banner2.png"
import bannerImage3 from "../../images/banner3.png"
import bannerImage4 from "../../images/banner4.png"
import Chandigarh from "../../images/city/chandigarh.png"
import Delhi from "../../images/city/delhi.png"
import Bombay from "../../images/city/bombay.png"
import { motion } from 'framer-motion';
import Typewriter from '../../animation/Typewriter';

// Adjust your city data to include cityId for navigation
const popularCities = [
  { name: "Chandigarh", image: Chandigarh},
  { name: "Delhi", image: Delhi },
  { name: "Bombay", image: Bombay },
];


const Home = () => {
    // const [isImageLoaded, setImageLoaded] = useState(false);
    const navigate = useNavigate(); // Use navigate for redirection
    const cities = useSelector((state) => state.cityFetcherReducer.cities);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCityClick = (index) => {
        navigate(`/cities/${cities[index]._id}`);
    };

    return (
         <div style={{ background: 'linear-gradient(80deg, #9c27b0 0%, #000000 35%)', minHeight: '100vh', width: "100vw" }}>
           <NavBar />
            <Box sx={{ mt: 20, mb: 10, textAlign: 'center', color: '#fff', paddingTop: '20px', display: "flex", justifyContent: "center", flexDirection: 'column', alignItems:"center"}}>
                <Typography variant='h1' sx={{ textAlign: 'center', marginBottom: 3, color: 'white' }}>Welcome to  <span style={{ color: '#9c27b0' }}> Nightly</span></Typography>
                <Typography variant='h5' sx={{mb:5}}>Instant access to your favorite nightclubs, lounges, bars and parties nationwide</Typography>
                        {/* <Typewriter text="Welcome to Nightly" size="h2" duration={0.05}/>
                        <Typewriter text="Discover and book the best nightlife experiences around you" size="h6" duration={0.02}/> */}
                <Search />
            </Box>

            <Box sx={{my: 4, padding: 4, borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'}}>
                <Typography variant="h2" sx={{marginBottom: 6, color: 'white' }}>
                    Popular Cities
                </Typography>
            <Carousel
                autoPlay
                interval={3000}
                showIndicators={true}
                infiniteLoop
                useKeyboardArrows
                showThumbs={false}
                onClickItem={(index, item) => handleCityClick(index)} // Passing index to handleCityClick
            >
                {popularCities.map((city, index) => (
                    <Box key={city.name} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <img
                            src={city.image}
                            alt={city.name}
                            style={{
                                maxHeight: isMobile ? '200px' : '400px', // Responsive max-height
                                width: 'auto', // Keeps image aspect ratio
                                maxWidth: '100%', // Limits the image width on larger screens
                                display: 'block',
                                margin: '0 auto', // Centers the image horizontally
                                boxShadow: '0px 2px 8px rgba(0,0,0,0.3)' // Adds shadow to images for depth
                            }}
                        />
                    </Box>
                ))}
            </Carousel>
        </Box>
            
        {/* Feature-1 */}
           <Box sx={{  p: { xs: 2, md: 4 }, mt: 10, mb: 2, textAlign: 'center', color: '#fff', paddingTop: '20px', display: "flex", justifyContent: "center", flexDirection: 'column', alignItems:"center"}}>
                <Grid container spacing={2} justifyContent="center" sx={{ mb: 10 }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" sx={{ color: 'white', textAlign: 'center'}}> 
                        {/* fontSize: { xs: '2.2rem', sm: '2.125rem' } */}
                        Your Surest Way To Get<span style={{ color: '#9c27b0' }}> The Vibe </span>
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', textAlign: 'center', mt: 2}}>
                         Live pictures, videos, real-time updates 
                        </Typography>
                    </Grid>
                </Grid>
                <Box
                    component="img"
                    src={bannerImage1}
                    alt="Banner"
                    sx={{
                        width: { xs: '90%', lg: '40%' },
                        height: 'auto'
                    }}
                />
            </Box>

            {/* Feature-2*/}
            <Box sx={{p: { xs: 2, md: 4 }, mb: 10, textAlign: 'center', color: '#fff', paddingTop: '20px', display: "flex", justifyContent: "center", flexDirection: 'column', alignItems:"center"}}>
                <Grid container spacing={2} justifyContent="center" sx={{ mb: 10 }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" sx={{ color: 'white', textAlign: 'center'}}> 
                        {/* fontSize: { xs: '2.2rem', sm: '2.125rem' } */}
                            Your Fastest Way For <span style={{ color: '#9c27b0' }}>Night Reservations</span>
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', textAlign: 'center', mt: 2}}>
                           One-step booking, dynamic pricing, exclusive deals
                        </Typography>
                    </Grid>
                </Grid>
                <Box
                    component="img"
                    src={bannerImage2}
                    alt="Banner"
                    sx={{
                        width: { xs: '100%', lg: '50%' },
                        height: 'auto'
                    }}
                />
            </Box>
            {/* Feature-3*/}
            <Box sx={{  p: { xs: 2, md: 4 }, mb: 10, textAlign: 'center', color: '#fff', paddingTop: '20px', display: "flex", justifyContent: "center", flexDirection: 'column', alignItems:"center"}}>
                <Grid container spacing={2} justifyContent="center" sx={{ mb: 10 }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" sx={{ color: 'white', textAlign: 'center'}}> 
                            Your Smartest Way To <span style={{ color: '#9c27b0' }}>Enjoy Nights</span>
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', textAlign: 'center', mt: 2}}>
                           Nighly Points, reduced fees, huge community
                        </Typography>
                    </Grid>
                </Grid>
                <Box
                    component="img"
                    src={bannerImage3}
                    alt="Banner"
                    sx={{
                        width: { xs: '100%', lg: '50%' },
                        height: 'auto'
                    }}
                />
            </Box>

           {/* Feature-4*/}
            <Box sx={{ p: { xs: 2, md: 4 }, mb: 10, color: '#fff', display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center" }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%', mt: 4 }}>
                    <Grid item xs={12} md={6}>
                    <Typography variant="h3" sx={{ color: 'white'}}> 
                            Interested in joining <span style={{ color: '#9c27b0' }}>Nightly?</span>
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', mt: 2}}>
                           Contact us for details on how to register your place and join nightly's community 
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box
                            component="img"
                            src={bannerImage4}
                            alt="Banner"
                            sx={{
                                width: { xs: '100%', md: '100%' }, // Adjust width as per your design
                                height: 'auto'
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            
           <Footer />
        </div>
    );
}

export default Home;
