import { createSlice, configureStore } from '@reduxjs/toolkit'

const citySlice = createSlice({
    name: 'city',
    initialState : {
        cityName: "",
        cityId: "",
        cover_image: "",
        clubs: []
    },
    reducers: {
        updateClubsInCity: (state, action) => {
            state.cityName = action.payload.cityName;
            state.cityId = action.payload.cityId
            state.clubs = action.payload.clubs;
            state.cover_image = action.payload.coverImage
        }
    }
})

export const { updateClubsInCity } = citySlice.actions
export default citySlice.reducer;