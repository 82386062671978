import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as api from '../../api/index.js';
import { Container, Typography, Box, Card, CardContent, Button, Collapse, CircularProgress, Dialog, DialogContent, DialogActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const PastTransactions = () => {
    const userId = useSelector(state => state.authReducer.authData._id);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedReservation, setExpandedReservation] = useState(null);
    const [expandedTransaction, setExpandedTransaction] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentBillImage, setCurrentBillImage] = useState('');

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await api.getPastTransactions(userId);
                setTransactions(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching transactions details:", error);
                setLoading(false);
            }
        };

        fetchTransactions();
    }, [userId]);

    const handleExpandReservation = (reservationId) => {
        setExpandedReservation(expandedReservation === reservationId ? null : reservationId);
    };

    const handleExpandTransaction = (transactionId) => {
        setExpandedTransaction(expandedTransaction === transactionId ? null : transactionId);
    };

    const handleViewBill = async (billId) => {
        try {
            const response = await api.getBillImage({ id: billId });
            setCurrentBillImage(response.data.billUrl);
            setOpenDialog(true);
        } catch (error) {
            console.error("Error fetching bill image:", error);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (loading) return <CircularProgress />;

    if (transactions.length === 0) {
        return (
            <Box sx={{ 
                mt: 2, 
                color: 'white', 
                p: 2,
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Typography align="center">You don't have any past reservations.</Typography>
            </Box>
        );
    }

    return (
        <Container sx={{ bgcolor: 'black', color: 'white', py: 2 }}>
            {transactions.map((transaction, index) => (
                <Box key={index} sx={{ my: 4 }}>
                    <Typography variant="h5" gutterBottom sx={{ borderBottom: 1, borderColor: 'white', pb: 1 }}>
                        {transaction.reservationDate}
                    </Typography>
                    <Card sx={{ mb: 3, bgcolor: '#424242', color: 'white', borderRadius: '10px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5" sx={{ color: "#9c27b0" }}>{transaction.clubName}</Typography>
                                <Typography variant="body1" color="white" sx={{ mb: 2 }}>
                                    <strong>Seat Type:</strong> {transaction.seatName} <br />
                                    <strong>Reservation Date:</strong> {transaction.reservationDate}<br />
                                    <strong>Reservation Time:</strong> {transaction.reservationTime}
                                </Typography>
                                <Typography variant="body2"><strong>Reservation Id:</strong> {transaction.reservationId}</Typography>
                                <Typography variant="body2"><strong>Transaction Id:</strong> {transaction.transactionId}</Typography>
                                <Typography variant="body2"><strong>Number of People:</strong> {transaction.numberOfPeople}</Typography>
                                <Typography variant="body2"><strong>Number of Reservations:</strong> {transaction.numberOfReservations}</Typography>
                                <Typography variant="body2"><strong>Original Price:</strong> {transaction.originalPrice}</Typography>
                                <Typography variant="body2"><strong>Price Paid:</strong> {transaction.finalPrice}</Typography>
                                <Typography variant="body2"><strong>Points Used:</strong> {transaction.pointsUsed}</Typography>
                                <Typography variant="body2"><strong>Time of Booking:</strong> {transaction.bookingTime}</Typography>
                                <Button 
                                    variant="contained" 
                                    color="secondary" 
                                    onClick={() => handleExpandReservation(transaction.reservationId)}
                                    sx={{ mt: 2 }}
                                >
                                    {expandedReservation === transaction.reservationId ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Button>
                            </CardContent>
                            <Collapse in={expandedReservation === transaction.reservationId}>
                                <CardContent>
                                    {transaction.transactions.map((trans, idx) => (
                                        <Box key={idx} sx={{ mb: 2 }}>
                                            <Typography variant="body2"><strong>Transaction ID:</strong> {trans.transactionId}</Typography>
                                            <Typography variant="body2"><strong>Amount:</strong> {trans.amount}</Typography>
                                            <Typography variant="body2"><strong>Transaction Time:</strong> {trans.paymentTime}</Typography>
                                            <Typography variant="body2"><strong>Status:</strong> {trans.status}</Typography>
                                            {trans.billImageId && (
                                                <Button 
                                                    variant="outlined" 
                                                    color="primary" 
                                                    onClick={() => handleViewBill(trans.billImageId)}
                                                    sx={{ mt: 2 }}
                                                >
                                                    View Bill Image
                                                </Button>
                                            )}
                                            <hr style={{ borderTop: '1px solid #fff', marginTop: '20px', marginBottom: '20px' }} />
                                        </Box>
                                    ))}
                                </CardContent>
                            </Collapse>
                        </Box>
                    </Card>
                </Box>
            ))}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <img src={currentBillImage} alt="Bill Image" style={{ width: '100%' }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default PastTransactions;
