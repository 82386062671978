import React from 'react';
import { Container, Typography, Box, Grid, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import NavBar from '../NavBar/NavBar';
import Footer from './Footer';
import aditPic from '../../images/aditPic.png';
import './styles.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Icon for bullet points

const AboutUs = () => {
  return (
    <div style={{ background: 'linear-gradient(280deg, #9c27b0 0%, #000000 30%)', minHeight: '100vh' }}>
        <NavBar/>
        <Container maxWidth="lg" sx={{ color: "white", py: 8, mt:9 }}>
            <Typography variant="h2" gutterBottom align="center" className='neon-purple'>
                About Nightly
            </Typography>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Introduction to Nightly
                </Typography>
                <Typography paragraph>
                    Nightly is not just another app; it's a revolution in the nightlife industry. It was born out of frustration and a longing for a seamless nightlife experience. No more endless lines, no more uncertainty about the night's vibe, and no more missing out on the best events around you. Nightly transforms your nightlife adventure into a smooth, exhilarating journey.
                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Our Mission
                </Typography>
                <Typography paragraph>
                    At Nightly, we're on a mission to redefine your nightlife experience. We aim to connect party-goers with the ultimate night out, offering hassle-free reservations, exclusive access to events, and unparalleled convenience. Nightly is your nightlife, simplified.
                </Typography>
            </Box>

            <Box my={4} sx={{backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    What Sets Us Apart
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple'/>
                        </ListItemIcon>
                        <ListItemText primary="Real-Time Vibes: Through live images uploaded by users, get a feel of the atmosphere before even stepping foot in the club" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple' />
                        </ListItemIcon>
                        <ListItemText primary="Exclusive Deals: Access special discounts and offers, making your night out more enjoyable without breaking the bank" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple' />
                        </ListItemIcon>
                        <ListItemText primary="Earn Points: Earn redeemable nightly points each day you upload pictures, which can be used to lower your future booking price" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple' />
                        </ListItemIcon>
                        <ListItemText primary="Single Control for Club Admin: Simply modify price and availability in real time and see summary of past reservations for improving strategies" />
                    </ListItem>
                </List>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Our Team
                </Typography>
                <Grid container spacing={2} sx={{ alignItems: "center", justifyContent: "center" }}>
                    <Grid item xs={12} sm={6} md={4} sx={{ textAlign: 'center' }}>
                        <img src={aditPic} alt="Adit Picture" style={{ borderRadius: '50%', width: 200, height: 200 }} />
                        <Typography variant="h6" gutterBottom className='neon-purple' sx={{ mt: 2 }}>Adit Jindal</Typography>
                        <Typography>CEO/Founder</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Contact Us
                </Typography>
                <Typography paragraph>
                    Have questions or want to partner with us? Get in touch at nightly@callidoratechnology.com.
                </Typography>
            </Box>

            <Box mt={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Our Promise
                </Typography>
                <Typography paragraph>
                    With Nightly, gone are the days of uncertainty about where to go or what to do. We promise not just a service but a nightlife transformation. No more wasted nights or missed connections. Just pure, unadulterated fun. Welcome to Nightly – where your night begins.
                </Typography>
            </Box>
        </Container>
        <Footer/>
    </div>
  );
};

export default AboutUs;
