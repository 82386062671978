import React, { useEffect, useState } from 'react';
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,Typography, Button, Box, Grid, Paper, Card, CardMedia, IconButton} from '@mui/material';
import * as api from '../../api/index.js';
import { editClubImages } from '../../reducers/club.js';
import { useSelector, useDispatch} from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { getUserData } from '../../reducers/auth.js';
 
const ImagesSection = () => {
    const dispatch = useDispatch();
    const [imagesData, setImagesData] = useState({});
    const user = useSelector((state) => state.authReducer.authData);
    const club = useSelector((state) => state.clubReducer);
    const [uploading, setUploading] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        const fetchImagesData = async () => {
            try {
                const payload = {
                    destinationID: club._id,
                    image_list: club.images
                };
                const response = await api.getImages(payload);
                // Assuming the response data structure is { "2023-03-14": [{url: "https://example.com/image1.jpg", ...}], ... }
                setImagesData(response.data);
            } catch (error) {
                console.error('Failed to fetch images data:', error);
            }
        };
    fetchImagesData();
    }, [refreshFlag]);

    const toggleRefreshFlag = () => setRefreshFlag(prev => !prev);

    const handleMediaUpload = async (file) => {
        if (!user) {
            setAlertMessage('You need to be logged in to proceed.');
            setAlertOpen(true);
            return
        } 
        if (!file) return;
        if (file.type.startsWith('video/')) {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                if (video.duration > 10) {
                    setAlertMessage('Video must be less than 10 seconds.');
                    setAlertOpen(true);
                } else {
                    uploadFile(file); // If it's a valid video, upload it
                }
            };
            video.src = URL.createObjectURL(file);
        } else {
            uploadFile(file); // Directly upload if it's an image
        }
    };

    const uploadFile = async (file) => {
        setUploading(true);
        try {
            const fileType = file.type.startsWith('video/') ? 'video' : 'image';
            const payload = {
                destinationID: club._id,
                fileName: file.name,
                fileType: file.type,
            };
            
            const signedUrlResponse = await api.getSignedUrl(payload); 
            const signedUrl = signedUrlResponse.data.url;
        
            // Perform the upload to S3
            const imageData = {
                    UploadedBy: user?._id,
                    URL:signedUrlResponse.data.filePath, 
                    fileName: file.name,
                    destinationID: club._id, 
                    type: user.isAdmin ? "club_admin": "club_user",
                    mediaType: fileType
                }
            const savedImage = await api.createImage(imageData)

            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type, 
                },
                body: file,
            };  
            await fetch(signedUrl, options);

            let updatedImages = [...club.images];
            updatedImages.push(savedImage.data._id);
            dispatch(editClubImages({images: updatedImages}))
            setAlertMessage('Upload successful! If this was your first upload, you received a Nightly Point!');
            setAlertOpen(true);

            //Update user
            const storedData = JSON.parse(localStorage.getItem('profile'));
            const response  = await api.getUserData({token: storedData.token, id: storedData._id});
            dispatch(getUserData(response.data)); 
        } catch (error) {
            if (error.message.includes('429')) {
                setAlertMessage('Upload limit of 5 images and videos per day reached.');
            } else {
                setAlertMessage('Upload unsuccessful! Try again later');
            }
            setAlertOpen(true);
            console.error('Error during image upload', error);
        } finally {
            toggleRefreshFlag();
            setUploading(false);
        }
    };

    return (
            <Box sx={{ mt: 2, color: 'white', p: 2 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card sx={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', height: 250,
                        borderStyle: 'dashed', backgroundColor: 'rgba(255, 255, 255, 0.08)', mb: 5
                    }}>
                        <IconButton component="label" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <AddIcon sx={{ fontSize: '3rem', color: 'gray' }} />
                            <Typography variant="body1" sx={{ mt: 1, color: 'gray' }}>Add Image/Video</Typography>
                            <input type="file" hidden accept="image/*,video/*" onChange={(e) => handleMediaUpload(e.target.files[0])} />
                        </IconButton>
                    </Card>
                    </Grid>
                    {Object.entries(imagesData).map(([date, images]) => (
                    <Box key={date} sx={{ width: '100%' }}>
                        <Typography variant="h6" sx={{ mt: 2, mb: 2, color: 'gray', pl: 2 }}>
                        {new Date(date).toLocaleDateString()}
                        </Typography>
                        <Grid container spacing={3}>
                        {images.map((image, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 250, backgroundColor: 'rgba(255, 255, 255, 0.08)' }}>
                                    {image.mediaType === 'video' ? (
                                        <video controls height="100%" style={{ maxHeight: 200, objectFit: 'cover' }}>
                                            <source src={image.URL} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ): (
                                    <CardMedia component="img" image={image.URL} alt={`Uploaded ${index}`} sx={{ maxHeight: 200, objectFit: 'cover' }}/>
                                    )}
                                    <Typography variant="body2" sx={{ color: 'gray', m: 1, textAlign: 'center' }}>
                                        Uploaded at {new Date(`${date}T${image.time}Z`).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
                                    </Typography>
                                </Card>
                            </Grid>
                        ))}
                        </Grid>
                    </Box>
                    ))} 
                </Grid>
                {uploading && (
                    <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                        <CircularProgress size={68} />
                    </Box>
                )}
                <Dialog
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Notification"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {alertMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAlertOpen(false)} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
    );
};

export default ImagesSection;