import React from 'react';
import { Container, Typography, Box, Grid, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import NavBar from '../NavBar/NavBar';
import Footer from './Footer';
import aditPic from '../../images/aditPic.png';
import './styles.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Icon for bullet points

const TOS = () => {
  return (
    <div style={{ background: 'linear-gradient(280deg, #9c27b0 0%, #000000 30%)', minHeight: '100vh', color: "white" }}>
        <NavBar/>
        <Container maxWidth="lg" sx={{ py: 8, mt:9}}>
            <Typography variant="h2" gutterBottom align="center" className='neon-purple'>
                Terms of Service
            </Typography>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography paragraph>
                Welcome to Nightly! Before you embark on your next nightlife adventure through our platform, it's important to understand these Terms of Service ("Terms"). By accessing or using Nightly's app, website, services, or any associated content (collectively, the "Service"), you agree to be bound by these Terms. If you do not agree to these Terms, you must not use our Service.
                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Using Nightly
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple'/>
                        </ListItemIcon>
                        <ListItemText primary="Eligibility: By agreeing to these Terms, you represent and warrant that you are of legal age. Clubs and Dining Establishments have the right to deny you entry if they find you were below minimum age. In this case Nightly will not refund your ticket."/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple' />
                        </ListItemIcon>
                        <ListItemText primary="Account Registration: To access certain features of our Service, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete." />
                    </ListItem>
                </List>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Privacy Policy
                </Typography>
                <Typography paragraph>
                Your privacy is crucial to us. Our Privacy Policy explains how we collect, use, and share your personal information. By using Nightly, you agree to be bound by our Privacy Policy, which is incorporated into these Terms.
                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Content
                </Typography>
                <Typography paragraph>
                    You are responsible for the content you upload to Nightly, including its legality, reliability, and appropriateness. By posting content to Nightly, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such content on and through the Service. In case of any violation, we reserve the right for legal action.
                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                Prohibited Activities
                </Typography>
                <Typography paragraph>
                You agree not to engage in any of the following prohibited activities: (i) copying, distributing, or disclosing any part of the Service in any medium, including without limitation by any automated or non-automated "scraping"; (ii) using any automated system, including without limitation "robots," "spiders," "offline readers," etc., to access the Service; (iii) transmitting spam, chain letters, or other unsolicited email; (iv) attempting to interfere with, compromise the system integrity or security or decipher any transmissions to or from the servers running the Service.
                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                Changes to Terms
                </Typography>
                <Typography paragraph>
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide at least 30 days' notice before any new terms take effect. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.  
                              </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                Termination
                </Typography>
                <Typography paragraph>
                We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
                                        </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                Governing Law
                </Typography>
                <Typography paragraph>
                These Terms shall be governed and construed in accordance with the laws of the jurisdiction in which Nightly operates, without regard to its conflict of law provisions.
                                        </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Contact Us
                </Typography>
                <Typography paragraph>
                    If you have any questions about these Terms, please contact us at nightly@callidoratechnology.com.
                </Typography>
            </Box>
        </Container>
        <Footer/>
    </div>
  );
};

export default TOS;
