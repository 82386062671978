import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Container, Card, CardMedia, CardActions, IconButton, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';
import * as api from '../../api/index.js';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import InfoIcon from '@mui/icons-material/Info';
import { format, parseISO, isSameDay } from 'date-fns';
import './styles.css';

const UploadBill = () => {
    const { reservationId } = useParams();
    const [billImage, setBillImage] = useState(null);
    const [amount, setAmount] = useState('');
    const [reservationDetails, setReservationDetails] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [discountedAmount, setDiscountedAmount] = useState(null);
    const [discountModalOpen, setDiscountModalOpen] = useState(false);
    const [discountDetails, setDiscountDetails] = useState(null);
    const [canPay, setCanPay] = useState(false);
    const [coverCharge, setCoverCharge] = useState(0);
    const [isFirstTransaction, setIsFirstTransaction] = useState(false);
    const [calculatedValues, setCalculatedValues] = useState({
        adjustedAmount: 0,
        coverChargeValue: 0,
        discountValue: 0
    });

    useEffect(() => {
        const fetchReservationDetails = async () => {
            try {
                const response = await api.getReservationById(reservationId);
                const reservation = response.data;
                setReservationDetails(reservation);

                setIsFirstTransaction(reservation.isFirstTransaction);
                setCoverCharge(parseFloat(reservation.OriginalPrice.$numberDecimal));

                // Check if the current date matches the reservation date
                const currentDate = new Date();
                const reservationDate = parseISO(reservation.ReservationDate);
                setCanPay(isSameDay(currentDate, reservationDate));
            } catch (error) {
                console.error("Error fetching reservation details:", error);
            }
        };

        fetchReservationDetails();
    }, [reservationId]);

    useEffect(() => {
        if (reservationDetails) {
            const values = calculateDiscount(reservationDetails, amount, isFirstTransaction);
            setCalculatedValues(values);
        }
    }, [amount, reservationDetails, isFirstTransaction]);

    const calculateDiscount = (reservation, billAmount, isFirstTransaction) => {
        let adjustedAmount = parseFloat(billAmount) || 0;
        let discountValue = 0;
        let coverChargeValue = 0;

        if (reservation.DiscountId) {
            const { percentage, minAmount, maxAmount } = reservation.DiscountId;
            if (adjustedAmount >= minAmount) {
                discountValue = (adjustedAmount * percentage) / 100;
                discountValue = maxAmount ? Math.min(discountValue, maxAmount) : discountValue;
                adjustedAmount -= discountValue;
            }
        }

        if (isFirstTransaction && coverCharge) {
            coverChargeValue = parseFloat(coverCharge);
            adjustedAmount -= coverChargeValue;
        }

        setDiscountedAmount(Math.max(adjustedAmount, 0));

        return { adjustedAmount: Math.max(adjustedAmount, 0), coverChargeValue, discountValue };
    };

    const handleImageChange = (e) => {
        setBillImage(e.target.files[0]);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handlePayment = async () => {
        if (!billImage || !amount) {
            alert("Please upload the bill image and enter the amount.");
            return;
        }

        setUploading(true);
        try {
            const signedUrlResponse = await api.getSignedUrl({ destinationID: reservationDetails.ClubId, fileName: billImage.name, fileType: billImage.type });
            const signedUrl = signedUrlResponse.data.url;

            const billData = {
                UploadedBy: reservationDetails.UserId,
                URL: signedUrlResponse.data.filePath,
                fileName: billImage.name,
                destinationID: reservationDetails.ClubId,
                type: 'bill',
                mediaType: 'image',
                ReservationId: reservationId,
                amount: discountedAmount // Use discounted amount
            };
            const savedBill = await api.createBill(billData);

            await fetch(signedUrl, {
                method: 'PUT',
                headers: { 'Content-Type': billImage.type },
                body: billImage,
            });

            paymentGateway(savedBill.data._id);

        } catch (error) {
            console.error('Failed to upload bill image:', error);
        } finally {
            setUploading(false);
        }
    };

    const paymentGateway = async (bill) => {
        const payload = {
            bill: bill
        };
        try {
            const response = await api.makeBillPayment(payload);
            window.location.href = response.data;
        } catch (error) {
            alert("Payment failed, try again!");
        }
    };

    const handleDiscountInfoClick = () => {
        if (reservationDetails && reservationDetails.DiscountId) {
            setDiscountDetails(reservationDetails.DiscountId);
            setDiscountModalOpen(true);
        }
    };

    const handleDiscountModalClose = () => {
        setDiscountModalOpen(false);
    };

    if (!reservationDetails) {
        return (
            <div>
                <NavBar />
                <Container sx={{ bgcolor: 'black', color: 'white', py: 2, minHeight: '100vh', my: 15 }}>
                    <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mt: 4 }}>
                        Loading...
                    </Typography>
                </Container>
                <Footer />
            </div>
        );
    }

    const { clubName, ReservationDate, TimeSlot, DiscountId } = reservationDetails;
    const reservationDate = format(parseISO(ReservationDate), 'PPP');
    const reservationStartTime = TimeSlot[0].startTime;

    const { adjustedAmount, coverChargeValue, discountValue } = calculatedValues;

    return (
        <div>
            <NavBar />
            <Container sx={{ bgcolor: 'black', color: 'white', py: 2, minHeight: '100vh', my: 15 }}>
                <Typography className="neon-purple" variant="h4" gutterBottom sx={{ textAlign: 'center', mt: 4 }}>
                    Upload Bill
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                    <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                        {clubName}
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>
                        Reservation Date: {reservationDate}
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', mb: 5 }}>
                        Reservation Time: {reservationStartTime}
                    </Typography>
                    {DiscountId && (
                        <Box sx={{ mb: 4, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:"column" }}>
                            <Typography variant="h6" gutterBottom>
                                Discount Offer: {DiscountId.name}
                            </Typography>
                            <IconButton onClick={handleDiscountInfoClick} sx={{ color: 'white' }}>
                                <InfoIcon />
                            </IconButton>
                        </Box>
                    )}
                    <Box sx={{ width: '80%', mb: 4, textAlign: 'center' }}>
                        {billImage ? (
                            <Card sx={{ width: '100%', position: 'relative', mx: 'auto' }}>
                                <CardMedia
                                    component="img"
                                    height="300"
                                    image={URL.createObjectURL(billImage)}
                                    alt="Bill"
                                    sx={{ maxHeight: 200, objectFit: 'cover' }}
                                />
                                <CardActions sx={{ justifyContent: 'center', position: 'absolute', bottom: 0, width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    <IconButton component="label" sx={{ color: "white" }}>
                                        <AddAPhotoIcon />
                                        <Typography variant="body2" component="span" sx={{ ml: 1, display: 'inline' }}>Replace Bill Image</Typography>
                                        <input type="file" hidden onChange={handleImageChange} />
                                    </IconButton>
                                </CardActions>
                            </Card>
                        ) : (
                            <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', maxWidth: 600, minHeight: 240, borderStyle: 'dashed', borderColor: 'action.disabled', mx: 'auto' }}>
                                <IconButton component="label" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'action.active' }}>
                                    <AddAPhotoIcon sx={{ fontSize: '4rem' }} />
                                    <Typography variant="body1">Add Bill Image</Typography>
                                    <input type="file" hidden onChange={handleImageChange} />
                                </IconButton>
                            </Card>
                        )}
                    </Box>
                    <TextField
                        label="Amount"
                        value={amount}
                        onChange={handleAmountChange}
                        sx={{ mb: 3, bgcolor: 'white', borderRadius: 1, width: '20%', textAlign: 'center' }}
                        inputProps={{ style: { textAlign: 'center' } }}
                    />
                    {discountedAmount !== null && !isNaN(discountedAmount) && (
                        <>
                            <Box sx={{ mb: 3, textAlign: 'center', width: '80%' }}>
                                <Typography variant="h5" sx={{ color: 'white', mb: 1 }}>
                                    Total Amount Due: ₹{discountedAmount.toFixed(2)}
                                </Typography>
                                <Divider sx={{ mb: 1, bgcolor: 'white' }} />
                                <Typography variant="body1" sx={{ color: 'white', mb: 1 }}>
                                    Original Bill Amount: ₹{parseFloat(amount).toFixed(2)}
                                </Typography>
                                {isFirstTransaction && (
                                    <Typography variant="body1" sx={{ color: 'white', mb: 1 }}>
                                        - Initial Cover Charge: ₹{coverChargeValue.toFixed(2)}
                                    </Typography>
                                )}
                                <Typography variant="body1" sx={{ color: 'white', mb: 1 }}>
                                    - Discount: ₹{discountValue.toFixed(2)}
                                </Typography>
                            </Box>
                        </>
                    )}
                    {!canPay && (
                        <Typography variant="h6" sx={{ color: 'red', mb: 3 }}>
                            Bill payment can only be made on the day of the reservation.
                        </Typography>
                    )}
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={handlePayment}
                        sx={{ mt: 2 }}
                        disabled={uploading || !canPay}
                    >
                        Pay Amount
                    </Button>
                    {uploading && (
                        <Box sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                        }}>
                            <CircularProgress size={68} />
                        </Box>
                    )}
                </Box>
            </Container>
            <Footer />
            <Dialog
                open={discountModalOpen}
                onClose={handleDiscountModalClose}
                aria-labelledby="discount-dialog-title"
                aria-describedby="discount-dialog-description"
            >
                <DialogTitle id="discount-dialog-title">Discount Details</DialogTitle>
                <DialogContent>
                    {discountDetails && (
                        <DialogContentText id="discount-dialog-description">
                            <Typography variant="body1">Name: {discountDetails.name}</Typography>
                            <Typography variant="body1">Percentage: {discountDetails.percentage}%</Typography>
                            <Typography variant="body1">Terms: {discountDetails.terms}</Typography>
                            <Typography variant="body1">Minimum Amount for discount: {discountDetails.minAmount ? `₹${discountDetails.minAmount}` : 'N/A'}</Typography>
                            <Typography variant="body1">Maximum Discount Amount: {discountDetails.maxAmount ? `₹${discountDetails.maxAmount}` : 'N/A'}</Typography>
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDiscountModalClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UploadBill;
