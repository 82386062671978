import { createSlice } from '@reduxjs/toolkit'

const pastReservationUserSlice = createSlice({
    name: 'pastReservationUser',
    initialState : {
        reservations: []
    },
    reducers: {
        updatePastReservationUser: (state, action) => {
            state.reservations = action.payload.data;
        },
        logoutPastReservation: (state, action) => {
            state.reservations = null;
        },
    }
})

export const { updatePastReservationUser, logoutPastReservation} = pastReservationUserSlice.actions
export default pastReservationUserSlice.reducer;