import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography, Container, Paper, Button, CircularProgress, Box } from '@mui/material';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import * as api from '../../api/index.js';
import './styles.css';

const BillConfirmed = () => {
    const { transactionId } = useParams();
    const [billDetails, setBillDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchBillDetails = async () => {
            try {
                const response = await api.getBillInfo(transactionId);
                setBillDetails(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching bill details:", err);
                setError('Failed to fetch bill details.');
                setLoading(false);
            }
        };
        fetchBillDetails();
    }, [transactionId]);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <div>
            <NavBar />
            <Container>
                <Box sx={{ my: 15, p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography className="neon-purple" sx={{color:"white", mb: 2}} variant="h4" gutterBottom textAlign="center">
                        Bill Confirmed
                    </Typography>
                    <Typography sx={{color:"white", mb:5}} variant="body1" gutterBottom textAlign="center">
                        We have sent you a confirmation email with your bill details to your registered email address. For any inquiries, reach out to us!
                    </Typography>
                    {billDetails.map((detail, index) => (
                        <Paper key={index} elevation={3} sx={{ p: 2, mb: 2, width: '100%', maxWidth: 600 }}>
                            <Typography><strong>Transaction ID:</strong> {detail.transactionId}</Typography>
                            <Typography><strong>Bill ID:</strong> {detail.billId}</Typography>
                            <Typography><strong>Reservation ID:</strong> {detail.reservationId}</Typography>
                            <Typography><strong>Reservation Date:</strong> {detail.reservationDate}</Typography>
                            <Typography><strong>Reservation Time:</strong> {detail.reservationTime}</Typography>
                            <Typography><strong>Name:</strong> {detail.userName}</Typography>
                            <Typography><strong>Club:</strong> {detail.clubName}</Typography>
                            <Typography><strong>Amount:</strong> {detail.amount}</Typography>
                            <Typography><strong>Payment Time:</strong> {detail.paymentTime}</Typography>
                            <Typography><strong>Status:</strong> {detail.status}</Typography>
                        </Paper>
                    ))}
                    <Button color="primary" component={Link} to="/">
                        Go Back to Home
                    </Button>
                </Box>
            </Container>
            <Footer />
        </div>
    );
};

export default BillConfirmed;
