import React, {useState, useEffect} from 'react';
import { Typography, Box, Paper, Grid, Button, Card, CardContent, CardActions } from '@mui/material';
import { useSelector } from 'react-redux';
import { GoogleMap, LoadScript, Marker, DirectionsRenderer } from '@react-google-maps/api';
import * as api from '../../api/index.js';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { format, parse, addDays } from 'date-fns';

const AboutSection = () => {
  const [location, setLocation] = useState(null);
  const club = useSelector((state) => state.clubReducer);
  const id = useSelector((state) => state.clubReducer._id);
  const openings = useSelector((state) => state.clubReducer.openings) || [];
  const [imagesData, setImagesData] = useState([]);
  const events = useSelector((state) => state.clubReducer.events) || [];
  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  
  useEffect(() => {
    // Function to fetch the geocoding data
    const fetchLocation = async () => {
      const URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(club.name+club.address)}&key=${googleMapsKey}`
      const response = await fetch(URL);
      const data = await response.json();
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setLocation({ lat, lng })
      }
    };
    const fetchProfileImages = async () => {
        try {
            const payload = {
              destinationID: id
            }
            const response = await api.getImagesAdmin(payload);
            setImagesData(response.data.profile_images);
        } catch (error) {
            console.error('Failed to fetch images data:', error);
        }
    };
    if (club.id !== "") {
      fetchLocation();
      fetchProfileImages();
    }
  }, [club]);

  const containerStyle = {
    width: '100%',
    height: '200px'
  };

  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  const getDirections = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const origin = `${position.coords.latitude},${position.coords.longitude}`;
        const destination = `${location.lat},${location.lng}`;
        const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
        window.open(url, '_blank');
      }, (error) => {
        console.error("Error getting current location: ", error);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const parseTime = (timeStr) => {
    return parse(timeStr, 'h:mma', new Date());
  };

  const getCurrentStatus = () => {
    const now = new Date();
    const currentDay = now.getDay();
    const currentTime = format(now, 'h:mma');
    const openingHours = openings[(currentDay + 6) % 7] || "Closed"; // Adjusting index for Sunday (0) being the last element

    if (openingHours === "Closed") {
      return "Closed";
    }

    const [opening, closing] = openingHours.split('-');
    const currentTimeParsed = parseTime(currentTime);
    const openingTimeParsed = parseTime(opening);
    let closingTimeParsed = parseTime(closing);

    if (closing.includes('AM')) {
      closingTimeParsed = addDays(closingTimeParsed, 1);
    }

    return (currentTimeParsed >= openingTimeParsed && currentTimeParsed <= closingTimeParsed) ? "Open" : "Closed";
  };

  const today = new Date().getDay();
  const todayOpeningHours = openings[(today + 6) % 7] || "Closed"; // Adjusting index for Sunday (0) being the last element


  return (
    <div>
      <Paper elevation={3} sx={{ p: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)', display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <Box sx={{ color: 'white', mt: 2, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Slider {...settings} style={{ marginBottom: '20px', maxWidth: '500px' }}>
            {imagesData.map((img, index) => (
              <Box key={index} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px',
                overflow: 'hidden'
              }}>
                <img src={img} alt={`Club Image ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </Box>
            ))}
          </Slider>
        </Box>
        <Box sx={{ color: 'white', mt: 2, flex: 1, px: { md: 4 } }}>
          <Typography variant="body1" gutterBottom sx={{ mb: 5 }}>{club.desc}</Typography>
        </Box>
        <Box sx={{ color: 'white', mt: 2, flex: 1, px: { md: 4 } }}>
          <Typography variant="h6" sx={{ color: "#9c27b0", mb: 1 }}>{getCurrentStatus()}</Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>Today's Opening Hours: {todayOpeningHours}</Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>Address: {club.address}</Typography>
          <Button variant="contained" color="primary" onClick={getDirections} fullWidth>
            Get Directions
          </Button>
        </Box>
      </Paper>
      <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} md={6}>
        <Card>
            <CardContent>
              <Typography variant="h6" sx={{ color: "#9c27b0", mb: 1 }}>This Week's Schedule:</Typography>
              {events.length > 0 ? (
                events.map((event, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{event[0]}</Typography>
                    <Typography variant="body2">{event[1]}</Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body2">No events scheduled for this week.</Typography>
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h6" sx={{ color: "#9c27b0", mb: 1 }}>Opening Hours:</Typography>
            {daysOfWeek.map((day, index) => (
              <Box key={day} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="subtitle1" sx={{ flexBasis: '30%', fontWeight: 'bold' }}>{day}:</Typography>
                <Typography variant="subtitle1" sx={{ flexBasis: '70%' }}>{openings[index] || "N/A"}</Typography>
              </Box>
            ))}
          </CardContent>
        </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" sx={{ color: "#9c27b0", mb: 1 }}>Contact Information:</Typography>
                  <Typography variant="subtitle1"><strong>Email:</strong> {club.email || 'N/A'}</Typography>
                  <Typography variant="subtitle1"><strong>Phone:</strong> {club.phone || 'N/A'}</Typography>
                  <Typography variant="subtitle1"><strong>City:</strong> {club.city}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              {location && (
                <Card>
                  <CardContent>
                    <Box sx={{ width: '100%', height: '200px', mb: 2 }}>
                      <LoadScript googleMapsApiKey={googleMapsKey}>
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={location}
                          options={mapOptions}
                          zoom={15}
                        >
                          <Marker position={location} />
                        </GoogleMap>
                      </LoadScript>
                    </Box>
                    <Typography variant="subtitle1"><strong>Address:</strong> {club.address}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button variant="contained" color="primary" onClick={getDirections} fullWidth>
                      Get Directions
                    </Button>
                  </CardActions>
                </Card>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutSection;