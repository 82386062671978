import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as api from '../../api/index.js';
import NavBar from '../NavBar/NavBar.js';
import { useSelector, useDispatch } from 'react-redux';
import { update } from '../../reducers/cityFetcher.js';
import { updateClub } from '../../reducers/club.js';
import { Button, Grid, Typography, Box } from '@mui/material';
import AboutSection from './AboutSection.js';
import './styles.css';
import ImagesSection from './ImagesSection.js';
import Availability from './Availability.js';
import Footer from '../Footer/Footer.js';

const ClubPage = () => {
  const { clubId } = useParams(); // Get city name from URL
  const cities = useSelector((state) => state.cities);
  const dispatch = useDispatch();
  const club = useSelector((state) => state.clubReducer);
  const [currentSection, setCurrentSection] = useState('about');

  useEffect(() => {
    const fetchClub = async () => {
        try {
            const response = await api.getClub(clubId);
            // Assuming the backend returns an array of city names
            dispatch(updateClub(response.data))
        } catch (error) {
            console.error("Error fetching club details:", error);
        }
    };
    if (clubId) {
      fetchClub();
    }
  }, [clubId, dispatch]); 

  useEffect(()=> {
    const fetchCities = async () => {
      try {
          if (!cities) {
          const response = await api.getCities();
            // Assuming the backend returns an array of city names
          dispatch(update(response.data))
          }
      } catch (error) {
          console.error("Error fetching cities:", error);
      }
    };
    if (!cities) {
      fetchCities();
    }
  }, [cities])

  const handleSectionChange = (section) => {
    setCurrentSection(section);
  };

  const getButtonStyles = (section) => ({
    color: "white",
    p: 3,
    backgroundColor: currentSection === section ? "rgba(255, 255, 255, 0.2)" : "transparent", 
    ':hover': {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  });

  return (
    <div>
      <NavBar />
      <Box sx={{ p: 2, color: "white", mt: 15 }}>
        <Typography variant="h2" className="neon-purple" sx={{ textAlign: 'center', mb: 5 }}>{club.name}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <Button sx={getButtonStyles('about')} onClick={() => handleSectionChange('about')}>About</Button>
          <Button sx={getButtonStyles('availability')} onClick={() => handleSectionChange('availability')}>Availability</Button>
          <Button sx={getButtonStyles('images')} onClick={() => handleSectionChange('images')}>Live View</Button>
        </Box>
        <Box sx={{ mt: 4 }}>
          {currentSection === 'about' && <AboutSection />}
          {currentSection === 'availability' && <Availability />}
          {currentSection === 'images' && <ImagesSection />}
        </Box>
      </Box>
      <Footer />
    </div>
  )
}

export default ClubPage;