//Use netlify for deployment of frontend

import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { reducers } from './reducers';
import App from './App';
import './index.css';
import { createTheme, ThemeProvider } from "@mui/material";
import { Analytics } from '@vercel/analytics/react';
//import 'typeface-roboto';

const store = configureStore({ reducer: reducers })

const theme = createTheme({
  palette: {
     primary: {
        main: "#9c27b0"
               },
     secondary: {
        main: "#9c27b0"
                }
  },
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Analytics />
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div style={{overflowX: 'hidden', width: "100%"}}>
        <App />
        </div>
      </ThemeProvider>
    </Provider>
  </StrictMode>
);
