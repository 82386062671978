import { createSlice, configureStore } from '@reduxjs/toolkit'

const seatInfoAdminSlice = createSlice({
    name: 'seatInfoAdmin',
    initialState : {
        seatInfoAdmin: [],
    },
    reducers: {
        updateSeatInfoAdmin: (state, action) => {
            state.seatInfoAdmin = action.payload;
        },
        editSeatInfoAdmin: (state, action) => {
            const index = state.seats.findIndex(seat => seat._id === action.payload._id);
            if (index !== -1) {
              // Seat exists, update it
              state.seats[index] = action.payload;
            } else {
              // New seat, add it to the state
              state.seats.push(action.payload);
            }
        },
    }
})

export const { updateSeatInfoAdmin, editSeatInfoAdmin } = seatInfoAdminSlice.actions
export default seatInfoAdminSlice.reducer;