import { combineReducers } from 'redux';

// import updateValReducer from './updateVal';
import cityReducer from './city';
import clubReducer from './club';
import seatReducer from './seats';
import discountReducer from './discounts';
import seatAvailabilityReducer from './seatAvailability';
import cityFetcherReducer from './cityFetcher';
import seatReservationReducer from './reservation';
import authReducer from './auth';
import pastReservationUserReducer from './pastReservationUser'
import adminReducer from "./admin"
import seatInfoAdminReducer from "./seatInfoAdmin"

export const reducers = combineReducers({ authReducer, 
    cityReducer, cityFetcherReducer, clubReducer, seatReducer, 
    seatAvailabilityReducer, seatReservationReducer, authReducer, 
    pastReservationUserReducer, adminReducer, seatInfoAdminReducer, discountReducer});
