import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../api/index.js'; 
import { updateClubAdmin } from '../../reducers/admin';
import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Box, Typography, Card, CardMedia, CardActions, IconButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const ImageUploadSection = () => {
    const dispatch = useDispatch();
    const { _id, cover_image, profile_images } = useSelector(state => state.adminReducer.clubAdmin);
    const [coverImageUrl, setCoverImageUrl] = useState(cover_image || '');
    const [profileImageUrls, setProfileImageUrls] = useState(profile_images || []);
    const user = useSelector((state) => state.authReducer.authData);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [imageToDelete, setImageToDelete] = useState(null); 

    useEffect(() => {
        // Initial fetch might be redundant if the images are already provided through the redux state
        // Consider removing if images are kept up-to-date in the redux store
        const fetchImages = async () => {
            try {
                const payload = {
                    destinationID: _id,
                };
                const response = await api.getImagesAdmin(payload);
                setProfileImageUrls(response.data.profile_images);
                setCoverImageUrl(response.data.cover_image);
            } catch (error) {
                console.error('Failed to fetch images data:', error);
            }
        };
        fetchImages();
    }, [_id, refreshFlag]);

    const toggleRefreshFlag = () => setRefreshFlag(prev => !prev);

    const uploadProfileImage = async (imageFile, index = null) => {
        if (!imageFile) return;
        setUploading(true);
        try {

            const signedUrlResponse = await api.getSignedUrl({destinationID: _id, fileName: imageFile.name, fileType: imageFile.type });
            const signedUrl = signedUrlResponse.data.url;
            const imageData = {
                data: {
                    UploadedBy: user?._id,
                    destinationID: _id, 
                    URL: signedUrlResponse.data.filePath,
                    fileName: imageFile.name,
                    originalID: index ? profile_images[index]: null,
                    type: "club",
                    mediaType: "image"
                },
                replaceIndex: index,
            }
            const savedImage = await api.createProfileImageAdmin(_id, user?._id, imageData);
            await fetch(signedUrl, {
                method: 'PUT',
                headers: { 'Content-Type': imageFile.type },
                body: imageFile,
            });
            let updatedProfileImages = [...profile_images];
            if (index != null) {
                if (index >= 0 && index < updatedProfileImages.length) {
                  updatedProfileImages[index] = savedImage.data._id;
                } else {
                  console.error("Invalid index for replacing an image.");
                }
              } else {
                updatedProfileImages.push(savedImage.data._id);
              }
            dispatch(updateClubAdmin({profile_images: updatedProfileImages}))
            toggleRefreshFlag();
        } catch (error) {
            console.error('Failed to upload cover image:', error);
        } finally {
            setUploading(false);
        }
    }

    const uploadCoverImage = async (imageFile) => {
        if (!imageFile) return;
        setUploading(true);
        try {
            const signedUrlResponse = await api.getSignedUrl({destinationID: _id, fileName: imageFile.name, fileType: imageFile.type });
            const signedUrl = signedUrlResponse.data.url;
            const imageData = {
                UploadedBy: user?._id,
                destinationID: _id, 
                URL: signedUrlResponse.data.filePath,
                fileName: imageFile.name, 
                originalID: cover_image,
                type: "club",
                mediaType: "image"
            }
            const savedImage = await api.createCoverImageAdmin(_id, user?._id, imageData);

            await fetch(signedUrl, {
                method: 'PUT',
                headers: { 'Content-Type': imageFile.type },
                body: imageFile,
            });

            console.log("succesfully uploaded file to s3")

            dispatch(updateClubAdmin({cover_image: savedImage.data._id}))
            toggleRefreshFlag();
        } catch (error) {
            console.error('Failed to upload cover image:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleFileChange = (event, type, index=null) => {
        const file = event.target.files[0];
        if (type === 'cover') {
            uploadCoverImage(file);
        } else if (type === 'profile') {
            uploadProfileImage(file, index);
        }
    };

    const handleOpenDialog = (index) => {
        setAlertMessage('Are you sure you want to delete this image? This action cannot be undone.');
        setImageToDelete(index);
        setAlertOpen(true);
    };
    
    const handleCloseDialog = () => {
        setAlertOpen(false);
    };
    
    const confirmDelete = async () => {
        if (imageToDelete !== null) {
            await removeProfileImage(imageToDelete);
        }
        handleCloseDialog();
    };

    const removeProfileImage = async (imageIndex) => {
        setUploading(true);
        const imageToRemove = profile_images[imageIndex];
        // Check if the image to remove exists
        if (imageToRemove) {
            const imageData = {
                UploadedBy: user?._id,
                destinationID: _id,
                originalID: imageToRemove // Assuming each image object has an _id field
            };
            try {
                await api.deleteProfileImageAdmin(_id, user?._id, imageData);
                const updatedProfileImages = profile_images.filter((_, index) => index !== imageIndex);
                dispatch(updateClubAdmin({profile_images: updatedProfileImages}));
                toggleRefreshFlag(); 
            } catch (error) {
                console.error('Failed to delete profile image:', error);
            } finally {
                setUploading(false);
            }
        } else {
            console.log('Image to remove not found at index:', imageIndex);
        }
    };
    
    return (
        <Box sx={{ color: 'white', p: 3,  width: "100%"  }}>
            {/* Cover Image Section */}
            <Typography variant="h6" gutterBottom> Cover Image </Typography>
            <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {coverImageUrl ? (
                    <Card sx={{ width: '100%', position: 'relative' }}>
                        <CardMedia
                            component="img"
                            height="300"
                            image={coverImageUrl}
                            alt="Cover"
                            sx={{ width: '100%', maxHeight: 345, objectFit: 'cover' }}
                        />
                        <CardActions sx={{ justifyContent: 'center', position: 'absolute', bottom: 0, width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                            <IconButton component="label" sx={{ color: "white" }}>
                                <AddAPhotoIcon />
                                <Typography variant="body2" component="span" sx={{ ml: 1, display: 'inline' }}>Replace Cover Image</Typography>
                                <input type="file" hidden onChange={(e) => handleFileChange(e, 'cover')} />
                            </IconButton>
                        </CardActions>
                    </Card>
                ) : (
                    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', maxWidth: 600, minHeight: 240, borderStyle: 'dashed', borderColor: 'action.disabled' }}>
                        <IconButton component="label" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'action.active' }}>
                            <AddIcon sx={{ fontSize: '4rem' }} />
                            <Typography variant="body1">Add Cover Image</Typography>
                            <input type="file" hidden onChange={(e) => handleFileChange(e, 'cover')} />
                        </IconButton>
                    </Card>
                )}
            </Box>

    
            {/* Profile Images Section */}
            <Typography variant="h6" gutterBottom>
            Profile Images
            </Typography>
            <Grid container spacing={2}>
                {profileImageUrls.map((image, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={image}
                                alt={`Profile ${index}`}
                            />
                            <CardActions>
                                <IconButton color="error" onClick={() => handleOpenDialog(index)} sx={{ mr: 1 }}>
                                    <DeleteIcon />
                                    <Typography variant="body2" color="error" sx={{ ml: 0.5 }}>Delete</Typography>
                                </IconButton>
                                <IconButton color="primary" component="label" sx={{ color: '#9c27b0' }}>
                                    <AddAPhotoIcon />
                                    <Typography variant="body2" sx={{ color: '#9c27b0', ml: 0.5 }}>Replace</Typography>
                                    <input type="file" hidden onChange={(e) => { handleFileChange(e, 'profile', index); }} />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', borderStyle: 'dashed' }}>
                        <IconButton component="label" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <AddIcon sx={{ fontSize: '3rem', color: 'gray' }} />
                            <Typography variant="body1" sx={{ mt: 1, color: 'gray' }}>Add Image</Typography>
                            <input type="file" hidden onChange={(e) => handleFileChange(e, 'profile')} />
                        </IconButton>
                    </Card>
                </Grid>
            </Grid>
            {uploading && (
                        <Box sx={{
                            position: 'fixed', // Fixed position
                            top: 0, // Start from top
                            left: 0, // Start from left
                            width: '100%', // Full width
                            height: '100%', // Full height
                            display: 'flex',
                            alignItems: 'center', // Center vertically
                            justifyContent: 'center', // Center horizontally
                            backgroundColor: 'rgba(0, 0, 0, 0.5)' // Semi-transparent background
                        }}>
                            <CircularProgress size={68} />
                        </Box>
            )}
            <Dialog
                open={alertOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {alertMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
    
};

export default ImageUploadSection;
