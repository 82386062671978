import React, { useState } from 'react';
import { Grid, Typography, Button, Box, TextField, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import * as api from '../../api/index.js';
import { getUserData } from '../../reducers/auth.js';
import PasswordResetIcon from '@mui/icons-material/VpnKey';
 
const ProfileInfo = () => {
    const user = useSelector((state) => state.authReducer.authData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedUser, setEditedUser] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email,
    });

    const handleEditToggle = () => {
        setIsEditMode(!isEditMode);
    };

    const handleChange = (prop) => (event) => {
        setEditedUser({ ...editedUser, [prop]: event.target.value });
    };
    const cancelChange = () => {
        setIsEditMode(false);
    };

    const handleSave = async () => {
        try {
            const storedData = JSON.parse(localStorage.getItem('profile'));
            const updatedUser = await api.updateUser(user._id, storedData.token, editedUser);
            dispatch(getUserData(updatedUser))
            setIsEditMode(false);
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', color: "white" }}>
            {isEditMode ? (
                <Grid container spacing={2} justifyContent="center">
                    {Object.entries(editedUser).map(([key, value]) => (
                        <Grid item xs={12} sm={12} key={key}>
                            <TextField
                                fullWidth
                                label={key.charAt(0).toUpperCase() + key.slice(1)}
                                value={value}
                                onChange={handleChange(key)}
                                variant="outlined"
                                sx={{
                                    '& .MuiInputBase-input': {
                                        color: 'white', // Text color
                                      },
                                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#9c27b0', // Change border color to purple on hover
                                    },
                                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#9c27b0', // Keep border color purple when focused
                                    },
                                    "& .MuiInputLabel-outlined": {
                                        // Color when not focused:
                                        color: "#9c27b0",
                                    }
                                }}
                                type={key === 'password' ? 'password' : 'text'}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Button onClick={handleSave} variant="contained" sx={{ mt: 2, backgroundColor: "white", color: "black"}}>
                            Save Changes
                        </Button>
                        <Button onClick={cancelChange} variant="contained" sx={{ mt: 2, backgroundColor: "white", color: "black"}}>
                            Cancel Changes
                        </Button>
                    </Grid>
                </Grid>

            ) : (
                <>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        First Name: {user.firstName}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        Last Name: {user.lastName}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        Phone Number: {user.phone}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        Email: {user.email}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        Password: ********
                    </Typography>
                    <Button startIcon = {<EditIcon /> } onClick={handleEditToggle}  sx={{ mt: 2, color: "#9c27b0"}}>
                        Edit Profile
                    </Button>
                </>
            )}

            {!isEditMode && (
                <Button
                    startIcon={<PasswordResetIcon />}
                    sx={{ mt: 2, color: "#9c27b0"}}
                    onClick={() => navigate('/forgotPassword')}
                >
                    Reset Password
                </Button>
            )}
        </Box>
        
    );
};

export default ProfileInfo;
