import React, {useState, useEffect} from 'react'
import {Avatar, Button, Paper, Grid, Typography, Container, ThemeProvider} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Input from './Input';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { login } from '../../reducers/auth';
import * as api from '../../api/index.js';

const AuthForm = ({signUp}) => {
    const initialState = {firstName: '', lastName: '', email: '', password: '', confirmedPassword: ''};
    const [showPassword, setShowPassword] = useState(false);
    const [isSignUp, setIsSignUp] = useState(signUp);
    const dispatch = useDispatch();
    const handleShowPassword = () => setShowPassword((prev)=>!prev);
    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialState)

    useEffect(() => {
        setIsSignUp(signUp);
    }, [signUp]);

    const handleSubmit = async (e) => {
        e.preventDefault(); //prevents reloads on submit
        try {
            let response;
            if (isSignUp) {   
                response = await api.signup(formData);
            } else {
                response = await api.signin(formData);
            }
            dispatch(login(response.data));
            navigate("/");
          } catch (error) {
            alert(error.response.data.message);
          }
    }

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]:e.target.value});
    }
    
    const switchMode = () => {
        setIsSignUp((prev)=>!prev);
        setShowPassword(false);
    };


    // const GoogleSuccess = async (res) => {
    //    const result = res?.profileObj;
    //    const token = res?.tokenId;

    //    try {
    //        dispatch({type:'AUTH', data:{result, token}});
    //        navigate('/home');
    //    } catch(error) {
    //        console.log(error);
    //    }
    // }

    // const GoogleFailure = () => {
    //     console.log("Google Sign In was unsuccesful");
    // }

    return (
        <Container component="main" maxWidth="xs" sx={{mt:10}}>
            <Paper elevation={3} sx={{ 
                marginTop: 15,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2
            }}>
                <Avatar sx={{ m: 1, bgcolor: 'black' }}>
                <LockOutlinedIcon style={{ color: "white"}}/>
                </Avatar>
                <Typography variant="h5" sx ={{marginBottom: 2}}>{isSignUp ? 'Sign Up' : 'Sign In'}</Typography>
                <form noValidate sx={{
                width: '100%', // Fix IE 11 issue.
                marginTop: 3,
                }} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {
                            isSignUp && (
                                <>
                                    <Input name="firstName" label="First Name" handleChange={handleChange} autoFocus half/>
                                    <Input name="lastName" label="Last Name" handleChange={handleChange} half/>
                                    <Input name="phone" label="Phone Number" handleChange={handleChange} />
                                </>
                            )
                        }
                        <Input name="email" label="Email Address" handleChange={handleChange} type="email"/>
                        <Input name="password" label="Password" handleChange={handleChange} type={showPassword? "text":"password"} handleShowPassword={handleShowPassword}/>
                        { isSignUp && <Input name = "confirmedPassword" label="Repeat Password" handleChange={handleChange} type="password"/> }
                    </Grid>
                    <Button sx ={{mt:3}} type="submit" fullWidth>{isSignUp? 'Sign Up' : 'Sign In'}</Button>
                    {/* <GoogleOAuthProvider clientId="397333843299-90u0adjlgqvg129so38p90mf11c9sqlm.apps.googleusercontent.com">
                        <GoogleLogin 
                            render={(renderProps)=> (
                                <Button color="primary" fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled} startIcon={<Icon/>} variant="contained">
                                   SIGN IN WITH GOOGLE
                                </Button>
                            )}
                            onSuccess={GoogleSuccess}
                            onFailure={GoogleFailure}
                            cookiePolicy={"single_host_origin"}
                        />
                    </GoogleOAuthProvider> */}

                    <Grid container justifyContent="center">
                        <Grid item>
                            <Button onClick={switchMode}>
                                {isSignUp? 'Already have an account? Sign In': "Don't have an account? Sign up"}
                            </Button>
                        </Grid>
                        <Grid item>
                                {!isSignUp && <Button onClick={() => navigate('/forgotPassword')}>
                                Forgot password?
                            </Button>}
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    )
}

export default AuthForm 
