import {BrowserRouter, Routes, Route} from 'react-router-dom'; 
import { TextField, ThemeProvider, createTheme } from '@mui/material';

import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import CityPage from './components/CityPage/CityPage';
import ClubPage from './components/ClubPage/ClubPage';
import Order from './components/Pay/Order';
import Profile from './components/Menu/Profile';
import PastReservations from './components/Menu/PastReservations';
import ForgotPassword from './components/AuthForm/ForgotPassword';
import ResetPassword from './components/AuthForm/ResetPassword';
import AboutUs from './components/Footer/AboutUs';
import PrivacyPolicy from './components/Footer/PrivacyPolicy';
import TOS from './components/Footer/TOS';
import RCP from './components/Footer/RCP';
import AccessCodePage from './components/AuthForm/AccessCodePage';
import WithAccessCheck from './components/AuthForm/WithAccessCheck'
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import OrderConfirmed from './components/Pay/OrderConfirmed';
import OrderFailed from './components/Pay/OrderFailed';
import UploadBill from './components/Pay/PayBill';
import BillConfirmed from './components/Pay/BillConfirmed';


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
        <Routes>  
            <Route element={<WithAccessCheck/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="/auth/login" element={<Auth signUp={false}/>}/>
                <Route path="/auth/signup" element={<Auth signUp={true}/>}/>
                <Route path="/cities/:cityId" element={<CityPage/>}/>
                <Route path="/clubs/:clubId" element={<ClubPage/>}/>
                <Route path="/pay/" element={<Order/>}/>
                <Route path="/profile" element={<Profile />}/>
                <Route path="/PastReservations" element={<PastReservations/>}/>
                <Route path="/upload-bill/:reservationId" element={<UploadBill />} />
                <Route path="/forgotPassword" element={<ForgotPassword/>}/>
                <Route path="/resetPassword/:token" element={<ResetPassword/>}/>
                <Route path="/aboutus" element={<AboutUs/>}/>
                <Route path="*" element={<AccessCodePage/>}/>
            </Route>
          <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
          <Route path="/tos" element={<TOS/>}/>
          <Route path="/rcp" element={<RCP/>}/> 
          <Route path="/access-code" element={<AccessCodePage/>}/>
          <Route path="/paysuccess/:transactionId" element={<OrderConfirmed/>}/>
          <Route path="/payfailure/:transactionId" element={<OrderFailed/>}/>
          <Route path="/billpaysuccess/:transactionId" element={<BillConfirmed/>}/>
        </Routes>
    </BrowserRouter>
  )
};

export default App;
