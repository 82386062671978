import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
    return (
        <Box component="footer" sx={{ bgcolor: 'black', color: 'grey.500', mt: 20, py: 3 }}>
            <Container maxWidth="lg">
                <Grid container spacing={5} justifyContent="space-between">
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                            Quick Links
                        </Typography>
                        <Link href="/aboutus" color="inherit" underline="none" sx={{ display: 'block', marginBottom: '8px', '&:hover': { color: '#9c27b0' } }}>About Us</Link>
                        <Link href="/privacypolicy" color="inherit" underline="none" sx={{ display: 'block', marginBottom: '8px', '&:hover': { color: '#9c27b0' } }}>Privacy Policy</Link>
                        <Link href="/tos" color="inherit" underline="none" sx={{ display: 'block', marginBottom: '8px','&:hover': { color: '#9c27b0' } }}>Terms of Service</Link>
                        <Link href="/rcp" color="inherit" underline="none" sx={{ display: 'block', '&:hover': { color: '#9c27b0' } }}>Refund and Cancellations</Link>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                            Contact Us
                        </Typography>
                        <Typography sx={{ color: 'grey.400' }}>Phone: +91 9915521444</Typography>
                        <Typography sx={{ color: 'grey.400' }}>Email: info@callidoratechnology.com</Typography>
                        <Typography sx={{ color: 'grey.400' }}>Address: SCO 12, Sector 66, Mohali, 160062</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                            Follow Us
                        </Typography>
                        <Box sx={{ '& a': { marginRight: '16px' }, '& svg': { fontSize: '24px', '&:hover': { color: '#9c27b0' } } }}>
                            <Link href="https://www.instagram.com/thenightly.in?igsh=bXptenBlYTVzczl1&utm_source=qr" color="inherit"><InstagramIcon /></Link>
                            <Link href="https://www.facebook.com" color="inherit"><FacebookIcon /></Link>
                            <Link href="https://www.twitter.com" color="inherit"><TwitterIcon /></Link>
                            {/* <Link href="https://www.linkedin.com" color="inherit"><LinkedInIcon /></Link> */}
                        </Box>
                    </Grid>
                </Grid>
                <Box textAlign="center" pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 0 }} sx={{ color: 'grey.500' }}>
                    Nightly, a unit of Callidora Technology Pvt Ltd &reg; {new Date().getFullYear()}
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
