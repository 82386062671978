import React, { useState } from 'react';
import { TextField, Button, Paper, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as api from '../../api/index.js'; // Assuming you have an endpoint to handle forgot password
import NavBar from '../NavBar/NavBar.js';
import { useSelector } from 'react-redux';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const isLoggedIn = JSON.parse(localStorage.getItem('profile'))

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // API call to backend to initiate forgot password process
            await api.forgotPassword({ email });
            alert('Please check your email for password reset instructions.');
            navigate('/auth/login'); // Redirect user to sign-in page
        } catch (error) {
            alert(error.response.data.message); // Handle errors
        }
    };

    return (
        <Container>
        <NavBar/>
        <Container component="main" maxWidth="xs" sx={{mt:10}}>
            <Paper elevation={3} sx={{ marginTop: 8, padding: 2 }}>
                <Typography component="h1" variant="h5">
                    {!isLoggedIn? "Forgot Password": "Reset Password"}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx = {{
                            color: 'white',
                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0', // Change border color to purple on hover
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0', // Keep border color purple when focused
                            },
                            "& .MuiInputLabel-outlined": {
                                // Color when not focused:
                                color: "#9c27b0",
                            }
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                    >
                        Send Reset Instructions
                    </Button>
                </form>
            </Paper>
        </Container>
        </Container>
    );
};

export default ForgotPassword;
