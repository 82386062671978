import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer.js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box, TextField, Chip, Divider, Button, Card, CardContent, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import { format, parse } from 'date-fns';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import * as api from '../../api/index.js';

const Order = () => {
    const selectedSeatsByType = useSelector(state => state.seatReservationReducer.selectedSeatsByType);
    const selectedDiscounts = useSelector(state => state.seatReservationReducer.selectedDiscounts);
    const token = JSON.parse(localStorage.getItem('profile'))?.token;
    const userPoints = useSelector(state => state.authReducer.authData?.points);
    const date = useSelector(state => state.seatReservationReducer.date);
    const seatIdsToName = useSelector(state => state.seatReservationReducer.seatIdsToName);
    const club = useSelector((state) => state.clubReducer);
    const authState = useSelector(state => state.authReducer);
    const [isLoggedIn, setIsLoggedIn] = useState(JSON.parse(localStorage.getItem('profile')));
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0.5 * 60); // 5 minutes in seconds
    const [timerVisible, setTimerVisible] = useState(false);
    const [pointsToUse, setPointsToUse] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [originalPrice, setOriginalPrice] = useState(0);
    const [generalTermsModalOpen, setGeneralTermsModalOpen] = useState(false);

    useEffect(() => {
        const totalPrice = calculateTotalPrice();
        setOriginalPrice(totalPrice);
        setTotalPrice(totalPrice);
    }, [selectedSeatsByType, seatIdsToName]);

    const handlePayment = () => {
        paymentGateway();
    };

    const paymentGateway = async () => {
        const payload = {
            reservations: {
                UserId: authState.authData._id,
                phone: authState.authData.phone,
                date: date,
                ClubId: club._id,
                selectedSeatsByType: selectedSeatsByType,
                selectedDiscounts: selectedDiscounts,
                timeSlots: Object.entries(selectedSeatsByType).reduce((acc, [seatId, selection]) => {
                    acc[seatId] = seatIdsToName[seatId].timeSlot;
                    return acc;
                }, {}),
                PointsUsed: pointsToUse,
            },
            totalAmount: totalPrice,
        };
        try {
            const response = await api.makePayment(payload);
            window.location.href = response.data;
        } catch (error) {
            alert("Payment failed, try again!");
        }
    };

    const getSeatType = (seatId) => {
        return seatIdsToName[seatId].seatName.toUpperCase();
    };

    const getTimeSlot = (seatId) => {
        const timeSlot = seatIdsToName[seatId].timeSlot;
        const parsedTimeSlot = parse(timeSlot, 'HH:mm', new Date());
        return format(parsedTimeSlot, 'hh:mm a');
    };

    const calculateTotalPrice = () => {
        let price = 0;
        Object.entries(selectedSeatsByType).forEach(([seatId, selection]) => {
            const seatPrice = getSeatPrice(seatId);
            if (selection.numberOfReservations > 1) {
                price += seatPrice * selection.numberOfReservations;
            } else {
                price += seatPrice * selection.numberOfPeople;
            }
        });
        return price;
    };

    const handleUsePoints = () => {
        if (pointsToUse > userPoints) {
            alert('You do not have enough points.');
            return;
        }
        const priceAfterPoints = Math.max(0, originalPrice - pointsToUse);
        setTotalPrice(priceAfterPoints);
    };

    const getSeatPrice = (seatId) => {
        return seatIdsToName[seatId].price;
    };

    const getDiscount = (seatId) => {
        const discount = selectedDiscounts[seatId];
        if (discount) {
            return `${discount.name} (${discount.percentage}%)`;
        }
        return 'No discount';
    };

    const handleLogin = () => {
        navigate("/auth/login");
    };

    const handleGeneralTermsOpen = () => {
        setGeneralTermsModalOpen(true);
    };

    if (!isLoggedIn) {
        return (
            <div>
                <NavBar />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Box textAlign="center">
                        <Typography sx={{ color: "white" }} variant="h5" gutterBottom>You need to be logged in to continue</Typography>
                        <Button onClick={handleLogin}>Log in</Button>
                    </Box>
                </div>
                <Footer />
            </div>
        );
    }

    if (Object.keys(selectedSeatsByType).length === 0) {
        return (
            <div>
                <NavBar />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Box textAlign="center">
                        <Typography sx={{ color: "white" }} variant="h5" gutterBottom>
                            You might have reloaded the page. Please go back to seat selection.
                        </Typography>
                        <Button variant="contained" onClick={() => navigate(-1)}>Go to Availabilities</Button>
                    </Box>
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <NavBar />
            <Box sx={{ padding: 4, color: "white", minHeight: "100vh", mt: 15 }}>
                <Typography sx={{ mb: 4, textAlign: "center" }} variant="h4" gutterBottom component="div" className="neon-purple">Order Summary</Typography>
                {timerVisible && (
                    <Typography variant="h6" sx={{ color: "white", textAlign: "center", mt: 2, mb: 2 }}>
                        {`Time remaining: ${Math.floor(remainingTime / 60)}:${(remainingTime % 60).toString().padStart(2, "0")}`}
                    </Typography>
                )}
                <Button variant="outlined" onClick={handleGeneralTermsOpen} sx={{ mt: 2, mb: 4, color: '#9c27b0', borderColor: '#9c27b0' }}>
                    Terms and Conditions
                </Button>
                <Typography sx={{ mb: 1 }} variant="h5">{club.name}</Typography>
                <Typography variant="body1" sx={{ mb: 5 }}>
                    Confirm your seat selection and proceed to billing information
                </Typography>
                {Object.entries(selectedSeatsByType).map(([SeatId, selection]) => (
                    <Card key={SeatId} sx={{ marginBottom: 2, bgcolor: 'grey.100', color: 'grey.900' }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                    {getSeatType(SeatId)}
                                </Typography>
                                {selection.numberOfReservations === 1 ? (
                                    <Chip label={`People: ${selection.numberOfPeople}`} variant="outlined" />
                                ) : (
                                    <Chip label={`Reservations: ${selection.numberOfReservations}`} variant="outlined" />
                                )}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                    {`Time Slot: ${getTimeSlot(SeatId)}`}
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                    {selection.numberOfReservations === 1 ? (
                                        <Chip label={`Booking Fees Per Person: ₹${getSeatPrice(SeatId)}`} variant="outlined" />
                                    ) : (
                                        <Chip label={`Booking Fees Per Reservation: ₹${getSeatPrice(SeatId)}`} variant="outlined" />
                                    )}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                                <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                    {`Discount on bill: ${getDiscount(SeatId)}`}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                ))}

                <Typography variant="h6" sx={{ mb: 3 }}>
                    Your Points: {userPoints}
                </Typography>
                <TextField
                    label="Points to Use"
                    type="number"
                    value={pointsToUse}
                    onChange={(e) => setPointsToUse(e.target.value)}
                    sx={{ width: '25ch', mb: 3, mr: 3 }}
                    InputProps={{
                        sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0', // Changes the border color to white
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0', // Keeps the border color white on hover
                            },
                            color: 'white', // Changes the input text color to white
                        },
                        inputProps: {
                            min: 0,
                            max: Math.min(originalPrice, userPoints),
                        }
                    }}
                    InputLabelProps={{
                        style: { color: '#9c27b0' },
                    }}
                />
                <Button onClick={handleUsePoints}>Use Points</Button>
                <Typography variant="h6">Total Price: ₹{totalPrice}</Typography>

                <Divider sx={{ my: 1, mt: 2, background: "white" }} />
                <Button
                    variant="contained"
                    startIcon={isProcessing ? <CircularProgress size={24} /> : <PaymentIcon />}
                    onClick={handlePayment}
                    sx={{ marginTop: 2, background: "white", color: "black" }}
                    disabled={isProcessing}
                >
                    {isProcessing ? "Processing..." : "Pay Now"}
                </Button>
            </Box>
            <Footer />

            <Dialog
                open={generalTermsModalOpen}
                onClose={() => setGeneralTermsModalOpen(false)}
                aria-labelledby="general-terms-dialog-title"
                aria-describedby="general-terms-dialog-description"
            >
                <DialogTitle id="general-terms-dialog-title">{"General Terms and Conditions"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="general-terms-dialog-description">
                        Please note the following terms and conditions for reservations and discounts:
                        <ul>
                        <li>Booking fees will be adjusted in the final bill.</li>
                        <li>Discounts are only applicable under the following conditions:
                            <ul>
                            <li>The reservation is made for the exact number of people as specified.</li>
                            <li>The reservation is completed and confirmed prior to the event date and time.</li>
                            <li>Payments are processed exclusively through the Nightly platform by accessing your profile and selecting past reservations.</li>
                            </ul>
                        </li>
                        <li>All sales are final and non-refundable</li>
                        <li>All reservations are subject to availability and confirmation.</li>
                        <li>Please bring a valid ID and your reservation confirmation email for entry.</li>
                        <li>The establishment reserves the right to deny entry to any guest not complying with the dress code or policies.</li>
                        <li>All guests must be of legal age. Denial based on age are not subject to refunds from Nightly.</li>
                        <li>By proceeding with the reservation, you agree to all the above terms and conditions.</li>
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setGeneralTermsModalOpen(false)} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Order;
