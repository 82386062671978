import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../api/index.js';
import { updatePastReservationUser } from '../../reducers/pastReservationUser';
import { format, parseISO, compareDesc } from 'date-fns'; // For date formatting and comparison
import { Grid, Container, Typography, Box, Card, CardContent, CardMedia, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PastReservations = () => {
    const dispatch = useDispatch();
    const reservations = useSelector(state => state.pastReservationUserReducer.reservations) || [];
    const userId = useSelector(state => state.authReducer.authData._id);
    const navigate = useNavigate();
    const [clubCovers, setClubCovers] = useState({});

    useEffect(() => { 
        const fetchReservations = async () => {
            try {
                const response = await api.getReservations(userId);
                dispatch(updatePastReservationUser(response));
            } catch (error) {
                console.error("Error fetching reservations details:", error);
            }
        };

        fetchReservations();
    }, [dispatch, userId]);

    useEffect(() => {
        const fetchClubCover = async (clubId) => {
            try {
                const response = await api.getClubCover(clubId);
                if (response.data.coverImageUrl) {
                    setClubCovers(prev => ({ ...prev, [clubId]: response.data.coverImageUrl }));
                } else {
                    console.error(`No cover image URL found for club ID: ${clubId}`);
                }
            } catch (error) {
                console.error("Error fetching club cover image:", error);
            }
        };
        reservations.forEach(reservation => {
            if (reservation.ClubId && !clubCovers[reservation.ClubId]) {
                fetchClubCover(reservation.ClubId);
            }
        });
    }, [reservations]);

    // Group reservations by DateBooked
    const groupedReservations = reservations.reduce((acc, reservation) => {
        const date = format(parseISO(reservation.ReservationFor), 'yyyy-MM-dd');
        if (!acc[date]) acc[date] = [];
        acc[date].push(reservation);
        return acc;
    }, {});

    // Sort dates in descending order
    const sortedDates = Object.keys(groupedReservations).sort((a, b) => compareDesc(parseISO(a), parseISO(b)));

    if (sortedDates.length === 0) {
        return (
            <Box sx={{ 
                mt: 2, 
                color: 'white', 
                p: 2,
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', // Centers content horizontally
                justifyContent: 'center', // Centers content vertically if needed
            }}>
                <Typography align="center">You don't have any past reservations.</Typography>
            </Box>
        );
    }

    return (
        <Container sx={{ bgcolor: 'black', color: 'white', py: 2 }}>
            {sortedDates.map((date, index) => (
                <Box key={index} sx={{ my: 4 }}>
                    <Typography variant="h5" gutterBottom sx={{ borderBottom: 1, borderColor: 'white', pb: 1 }}>
                        {format(parseISO(date), 'PPP')}
                    </Typography>
                    {groupedReservations[date].map((reservation, idx) => {
                        const coverImage = clubCovers[reservation.ClubId];
                        return (
                            <Card key={idx} sx={{ display: 'flex', mb: 3, bgcolor: '#424242', color: 'white', borderRadius: '10px' }}>
                                {coverImage && (
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 151 }}
                                        image={coverImage}
                                        alt={`Club cover`}
                                    />
                                )}
                                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h5" sx={{ color: "#9c27b0" }}>{reservation.ClubName}</Typography>
                                        <Typography variant="body1" color="white" sx={{ mb: 2 }}>
                                            <strong>Seat Type:</strong> {reservation.SeatTitle} <br />
                                           <strong>Reservation Date:</strong> {new Date(reservation.ReservationFor).toLocaleDateString()}<br />
                                           <strong>Reservation Time:</strong> {reservation.reservationTime[0].startTime}
                                        </Typography>
                                        <Typography variant="body2"><strong>Reservation Id:</strong> {reservation._id}</Typography>
                                        <Typography variant="body2"><strong>Transaction Id:</strong> {reservation.TransactionId}</Typography>
                                        <Typography variant="body2"><strong>Number of People:</strong> {reservation.numberOfPeople}</Typography>
                                        <Typography variant="body2"><strong>Number of Reservations:</strong> {reservation.numberOfReservations}</Typography>
                                        <Typography variant="body2"><strong>Original Price:</strong> {reservation.OriginalPrice?.$numberDecimal}</Typography>
                                        <Typography variant="body2"><strong>Price Paid:</strong> {reservation.FinalPrice?.$numberDecimal}</Typography>
                                        <Typography variant="body2"><strong>Points Used:</strong> {reservation.PointsUsed}</Typography>
                                        <Typography variant="body2"><strong>Time of Booking:</strong> {new Date(reservation.DateBooked).toLocaleString()}</Typography>
                                    </CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pb: 2 }}>
                                        <Button 
                                            variant="contained" 
                                            color="secondary" 
                                            onClick={() => navigate(`/upload-bill/${reservation._id}`)}
                                        >
                                            Pay Bill
                                        </Button>
                                    </Box>
                                </Box>
                            </Card>
                        );
                    })}
                </Box>
            ))}
        </Container>
    );
};

export default PastReservations;
