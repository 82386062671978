import React, { useState } from 'react';
import { Grid, Typography, Button, Box, TextField, IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import * as api from '../../api/index.js';
import { updateClubAdmin } from '../../reducers/admin.js';

const ClubProfile = () => {
    const club = useSelector((state) => state.adminReducer.clubAdmin);
    const user = useSelector((state) => state.authReducer.authData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedClub, setEditedClub] = useState({
        clubName: club.name,
        city: club.city,
        address: club.address,
        desc: club.desc,
        email: club.email,
        phone: club.phone,
        openings: club.openings.map(opening => {
            const [startTime, endTime] = opening.split('-');
            const [start, startAMPM] = [startTime.slice(0, -2), startTime.slice(-2)];
            const [end, endAMPM] = [endTime.slice(0, -2), endTime.slice(-2)];
            return { start, startAMPM, end, endAMPM };
        }),
        events: club.events.map(event => ([...event])),
    });

    const handleEditToggle = () => {
        setIsEditMode(!isEditMode);
    };

    const handleChange = (prop) => (event) => {
        setEditedClub({ ...editedClub, [prop]: event.target.value });
    };
    const cancelChange = () => {
        setIsEditMode(false);
    }; 

    const handleSave = async () => {
        try {
            const updatedOpenings = editedClub.openings.map(opening => `${opening.start}${opening.startAMPM}-${opening.end}${opening.endAMPM}`);
            const updatedClubData = { ...editedClub, openings: updatedOpenings };
            const updatedClub = await api.updateClub(club._id, user._id, updatedClubData);
            dispatch(updateClubAdmin(updatedClub));
            setIsEditMode(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleOpeningChange = (index, type, value) => {
        const newOpenings = [...editedClub.openings];
        newOpenings[index][type] = value;
        setEditedClub({ ...editedClub, openings: newOpenings });
    };

    const handleEventChange = (index, type, value) => {
        const newEvents = [...editedClub.events];
        newEvents[index][type] = value;
        setEditedClub({ ...editedClub, events: newEvents });
    };

    const daysOfWeek = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ];

    return (
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', color: "white" }}>
            {isEditMode ? (
                <Grid container spacing={2} justifyContent="center">
                    {Object.entries(editedClub).map(([key, value]) => {
                        if (key === 'openings') {
                            return (
                                <Grid item xs={12} key={key}>
                                    <Typography variant="h6" sx={{mb:2, color: "#9c27b0" }}>Timings</Typography>
                                    {value.map((opening, index) => (
                                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <TextField
                                                value={daysOfWeek[index]}
                                                variant="outlined"
                                                sx={{ mr: 2, flex: 1, 
                                                    '& .MuiInputBase-input': {
                                                        color: 'white', // Text color
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                            <TextField
                                                label="Start Time"
                                                value={opening.start}
                                                onChange={(e) => handleOpeningChange(index, 'start', e.target.value)}
                                                variant="outlined"
                                                sx={{ mr: 2, flex: 1,
                                                    '& .MuiInputBase-input': {
                                                        color: 'white', // Text color
                                                    },
                                                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#9c27b0', // Change border color to purple on hover
                                                    },
                                                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#9c27b0', // Keep border color purple when focused
                                                    },
                                                    "& .MuiInputLabel-outlined": {
                                                        color: "#9c27b0", // Color when not focused
                                                    }          
                                                }}
                                            />
                                            <FormControl variant="outlined" 
                                                sx={{ mr: 2, flex: 1,
                                                '& .MuiInputBase-input': {
                                                    color: 'white', // Text color
                                                },
                                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#9c27b0', // Change border color to purple on hover
                                                },
                                                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#9c27b0', // Keep border color purple when focused
                                                },
                                                "& .MuiInputLabel-outlined": {
                                                    color: "#9c27b0", // Color when not focused
                                                }}}>
                                                <InputLabel>Start AM/PM</InputLabel>
                                                <Select
                                                    value={opening.startAMPM}
                                                    onChange={(e) => handleOpeningChange(index, 'startAMPM', e.target.value)}
                                                    label="Start AM/PM"
                                                >
                                                    <MenuItem value="AM">AM</MenuItem>
                                                    <MenuItem value="PM">PM</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                label="End Time"
                                                value={opening.end}
                                                onChange={(e) => handleOpeningChange(index, 'end', e.target.value)}
                                                variant="outlined"
                                                sx={{ mr: 2, flex: 1, '& .MuiInputBase-input': {
                                                    color: 'white', // Text color
                                                },
                                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#9c27b0', // Change border color to purple on hover
                                                },
                                                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#9c27b0', // Keep border color purple when focused
                                                },
                                                "& .MuiInputLabel-outlined": {
                                                    color: "#9c27b0", // Color when not focused
                                                }      }}
                                            />
                                            <FormControl variant="outlined" sx={{ mr: 2, flex: 1, '& .MuiInputBase-input': {
                                                            color: 'white', // Text color
                                                        },
                                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#9c27b0', // Change border color to purple on hover
                                                        },
                                                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#9c27b0', // Keep border color purple when focused
                                                        },
                                                        "& .MuiInputLabel-outlined": {
                                                            color: "#9c27b0", // Color when not focused
                                                        }       }}>
                                                <InputLabel>End AM/PM</InputLabel>
                                                <Select
                                                    value={opening.endAMPM}
                                                    onChange={(e) => handleOpeningChange(index, 'endAMPM', e.target.value)}
                                                    label="End AM/PM"
                                                >
                                                    <MenuItem value="AM">AM</MenuItem>
                                                    <MenuItem value="PM">PM</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    ))}
                                </Grid>
                            );
                        }

                        if (key === 'events') {
                            return (
                                <Grid item xs={12} key={key}>
                                    <Typography variant="h6" sx={{mb:4, color: "#9c27b0" }}>Events</Typography>
                                    {value.map((event, index) => (
                                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <TextField
                                                label="Event Name"
                                                value={event[0]}
                                                onChange={(e) => handleEventChange(index, 0, e.target.value)}
                                                variant="outlined"
                                                sx={{ mr: 2, flex: 1, 
                                                    '& .MuiInputBase-input': {
                                                        color: 'white', // Text color
                                                    },
                                                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#9c27b0', // Change border color to purple on hover
                                                    },
                                                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#9c27b0', // Keep border color purple when focused
                                                    },
                                                    "& .MuiInputLabel-outlined": {
                                                        color: "#9c27b0", // Color when not focused
                                                    }       }}
                                            />
                                            <TextField
                                                label="Event Description"
                                                value={event[1]}
                                                onChange={(e) => handleEventChange(index, 1, e.target.value)}
                                                variant="outlined"
                                                sx={{ mr: 2, flex: 3,
                                                
                                                    '& .MuiInputBase-input': {
                                                        color: 'white', // Text color
                                                    },
                                                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#9c27b0', // Change border color to purple on hover
                                                    },
                                                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#9c27b0', // Keep border color purple when focused
                                                    },
                                                    "& .MuiInputLabel-outlined": {
                                                        color: "#9c27b0", // Color when not focused
                                                    }      }}
                                            />
                                        </Box>
                                    ))}
                                </Grid>
                            );
                        }

                        return (
                            <Grid item xs={12} sm={12} key={key}>
                                <TextField
                                    fullWidth
                                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                                    value={value}
                                    onChange={handleChange(key)}
                                    variant="outlined"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            color: 'white', // Text color
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9c27b0', // Change border color to purple on hover
                                        },
                                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#9c27b0', // Keep border color purple when focused
                                        },
                                        "& .MuiInputLabel-outlined": {
                                            color: "#9c27b0", // Color when not focused
                                        }
                                    }}
                                    type={key === 'password' ? 'password' : 'text'}
                                />
                            </Grid>
                        );
                    })}
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Button onClick={handleSave} variant="contained" sx={{ mt: 2, backgroundColor: "white", color: "black" }}>
                            Save Changes
                        </Button>
                        <Button onClick={cancelChange} variant="contained" sx={{ mt: 2, backgroundColor: "white", color: "black" }}>
                            Cancel Changes
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        Club Name: {club.name}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        City: {club.city}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        Address: {club.address}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        Description: {club.desc}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        Email: {club.email}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        Phone: {club.phone}
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1, color: "#9c27b0" }}>
                        Opening Hours: 
                    </Typography>
                    {club.openings.map((opening, index) => (
                        <Typography key={index} variant="body1" sx={{ mt: 1 }}>
                            {daysOfWeek[index]}: {opening}
                        </Typography>
                    ))}
                    <Typography variant="h6" sx={{ mt: 1, color: "#9c27b0" }}>
                        Events:
                    </Typography>
                    {club.events.map((event, index) => (
                        <Typography key={index} variant="body1" sx={{ mt: 1 }}>
                            {event[0]}: {event[1]}
                        </Typography>
                    ))}
                    <Button startIcon={<EditIcon />} onClick={handleEditToggle} sx={{ mt: 2, color: "#9c27b0" }}>
                        Edit Profile
                    </Button>
                </>
            )}
        </Box>
        
    );
};

export default ClubProfile;
