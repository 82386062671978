import { createSlice } from '@reduxjs/toolkit'

const cityFetcherSlice = createSlice({
    name: 'cityFetcher',
    initialState : {
        cities: []
    },
    reducers: {
        update: (state, action) => {
            state.cities = action.payload;
        }
    }
})

export const { update } = cityFetcherSlice.actions
export default cityFetcherSlice.reducer;