import React from 'react';
import { Button, Typography, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer.js'
import './styles.css';

const OrderFailed = () => {
    const navigate = useNavigate();  // This hook gives you access to the history instance that you may use to navigate.

    const goHome = () => {
        navigate("/")  // Navigating back to the homepage
    };

    return (
        <div>
            <NavBar />
            <Container>
                <Box textAlign="center" sx={{
                    marginTop: 15,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: 15,
                }}>
                    <Typography className="neon-purple" sx ={{color:"white"}} component="h1" variant="h5" gutterBottom>
                        Order Processing Failed
                    </Typography>
                    <Typography sx ={{color:"white"}} variant="body1" gutterBottom>
                        We're sorry, but there was a problem processing your order. Please try again later.
                    </Typography>
                    <Button
                        type="button"
                        onClick={goHome}
                        sx={{ mt: 5, mb: 2 }}
                    >
                        Go to Home
                    </Button>
                </Box>
            </Container>
            <Footer />
        </div>
    );
}

export default OrderFailed;
