import React from 'react'
import {Container, Typography} from '@mui/material';
import AuthForm from '../AuthForm/AuthForm';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

const Auth = ({signUp}) => {
    return (
        <div>
            <Container>
                <NavBar/>
                <AuthForm signUp={signUp}/>
                <Footer />
            </Container>
        </div>
    )
}

export default Auth
