import { createSlice, configureStore } from '@reduxjs/toolkit'

const seatAvailabilitySlice = createSlice({
    name: 'seatAvailabilities',
    initialState : {
        seatAvailabilities: [],
    },
    reducers: {
        updateSeatAvailability: (state, action) => {
            state.seatAvailabilities = action.payload;
        }
    }
})

export const { updateSeatAvailability } = seatAvailabilitySlice.actions
export default seatAvailabilitySlice.reducer;