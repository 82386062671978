import axios from 'axios';

//const API = axios.create({baseURL: 'http://localhost:5001' }); //change to heroku one

const API = axios.create({baseURL: 'https://nightly-backend-mnlxm.ondigitalocean.app' });

//Auth endpoints
export const verifyAccessCode = (formData) => API.post('/api/auth/verifyacesstoken', formData)
export const signup = (formData) => API.post('/api/auth/register', formData);
export const signin = (formData) => API.post('/api/auth/login', formData);
export const getUserData = (formData) => {
    const token = formData.token
    const id = formData.id
    return API.post('/api/auth/getUserData', {id:id}, 
            {
            headers: {'authorization': `Bearer ${token}`},
            })
}
export const forgotPassword = (password) => API.post('/api/auth/forgot-password', password);
export const resetPassword = (formData) => API.post('/api/auth/reset-password', formData);

//User endpoints
export const updateUser = (userId, token, formData) => {
    return API.put(`/api/users/${userId}`, formData,
    {
        headers: {'authorization': `Bearer ${token}`},
    });
}

//Cities endpoints
export const getCities = () => API.get("/api/location/get-cities");
export const getClubInCity = (cityId) => API.get(`/api/location/get-club-in-city/${cityId}`);

//Club endpoints
export const getClub = (clubId) => API.get(`/api/clubs/get-club/${clubId}`);
export const getClubCover = (clubId) => API.get(`/api/clubs/get-club-cover/${clubId}`);
export const updateClub = (clubId, userId, formData) => API.put(`/api/clubs/update-club/${clubId}/${userId}`, formData)

//Image endpoints
export const getSignedUrl = (formData) => API.post('/api/images/get-url', formData);
export const createImage = (formData) => API.post('/api/images/add-image', formData);
export const createBill = (formData) => API.post('/api/images/add-bill', formData);
export const getImages = (formData) => API.post('/api/images/get-images', formData);
export const getImagesAdmin = (formData) => API.post("api/images/get-images-admin", formData)
export const getImagesUser = (userId) => API.get(`api/images/get-images-user/${userId}`)
export const getClubImages = (cityId) => API.get(`api/images/get-club-images/${cityId}`)
export const createCoverImageAdmin = (clubId, userId, formData) => API.post(`api/images/add-cover-image/${clubId}/${userId}`, formData)
export const createProfileImageAdmin = (clubId, userId, formData) => API.post(`api/images/add-profile-image/${clubId}/${userId}`, formData)
export const deleteProfileImageAdmin = (clubId, userId, formData) => API.post(`api/images/delete-profile-image/${clubId}/${userId}`, formData)
export const deleteUserImage = (formData) => API.post("api/images/delete-user-image", formData)
export const getBillImage = (formData) => API.post(`api/images/get-bill-image`, formData)

//Seat endpoints
export const getSeatsInfo = (clubId) => API.get(`/api/seats/club-seats/${clubId}`);
export const getSeatsAvailability = (formData) => API.post(`/api/seats/available-seats/`, formData);
export const updateSeatInfo = (seatId, userId, clubId, formData) => API.put(`/api/seats/${seatId}/${userId}/${clubId}`, formData);
export const createSeatInfo = (clubId, userId, formData) => API.post(`/api/seats/create-seat/${clubId}/${userId}`, formData);
export const deleteSeatInfo = (seatId, userId, clubId) => API.delete(`/api/seats/${seatId}/${clubId}/${userId}`);
export const getSeatsInfoAdmin = (clubId, date=null) => {
    const queryParams = {};
    if (date) { 
        queryParams.date = date; // Add the date to query params if it's provided
    }
    return API.get(`/api/seats/seats-admin/${clubId}`, {
        params: queryParams
    });
}
export const modifySeats = (formData) =>  API.post(`/api/seats/modify-seat/`, formData);

//Reservation endpoints
export const getReservations = (userId) => API.get(`/api/reservation/get-reservations/${userId}`);
export const getTransactionInfo = (txnId) => API.post(`/api/reservation/get-transaction/${txnId}`);
export const getPastTransactions = (userId) => API.get(`/api/reservation/get-past-transactions/${userId}`);
export const getPastTransactionAdmin = (userId, clubId) => API.get(`/api/reservation/get-past-transactions-admin/${clubId}/${userId}`);
export const getReservationById = (reservationId) => API.get(`/api/reservation/get-reservation/${reservationId}`);

//Admin endpoints
export const getClubAdmin = (userId) => API.get(`/api/clubs/get-club-admin/${userId}`);
export const getReservationsAdmin = (userId, clubId) => API.post(`/api/reservation/get-reservations-admin/${clubId}`, {userId:userId});

//Payment endpoints
export const makePayment = (formData) => API.post('/api/pay/payment', formData)
export const makeBillPayment = (formData) => API.post('/api/billpay/payment', formData)
export const getBillInfo = (txnId) => API.post(`/api/billpay/get-transaction/${txnId}`);

//Discount endpoints
export const getDiscounts = (clubId) => API.get(`/api/discounts/getDiscounts/${clubId}`)
export const updateDiscount = (discountId, userId, clubId, formData) => API.put(`/api/discounts/updateDiscount/${discountId}/${userId}/${clubId}`, formData)
export const createDiscount = (userId, clubId, formData) => API.post(`/api/discounts/createDiscount/${userId}/${clubId}`, formData)
export const deleteDiscount = (discountId, userId, clubId) => API.delete(`/api/discounts/deleteDiscount/${discountId}/${userId}/${clubId}`)