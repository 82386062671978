import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, TextField, Button } from '@mui/material';
import * as api from '../../api/index.js'; // Your API file for making requests
import NavBar from '../NavBar/NavBar.js';

const ResetPassword = () => {
  const { token } = useParams(); // Extract the token from the URL
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }
    try {
      // Call your API to reset the password
      const formData = {
        token: token,
        password: password,
      }
      await api.resetPassword(formData);
      alert('Password successfully reset.');
      navigate('/auth/login'); // Redirect user to login page
    } catch (error) {
      console.error('Failed to reset password:', error);
      alert('Failed to reset password.');
    }
  };

  return (
    <Container>
    <NavBar/>
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
        <Typography component="h1" variant="h5">Reset Your Password</Typography>
        <form onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="new-password"
            sx = {{
                color: 'white',
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#9c27b0', // Change border color to purple on hover
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#9c27b0', // Keep border color purple when focused
                },
                "& .MuiInputLabel-outlined": {
                    // Color when not focused:
                    color: "#9c27b0",
                }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            autoComplete="new-password"
            sx = {{
                color: 'white',
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'purple', // Change border color to purple on hover
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'purple', // Keep border color purple when focused
                },
                "& .MuiInputLabel-outlined": {
                    // Color when not focused:
                    color: "purple",
                }
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, 
                color: "white", 
                backgroundColor: "purple", // Highlight background if selected
                ':hover': {
                backgroundColor: "black"
                }, }}
          >
            Reset Password
          </Button>
        </form>
      </Paper>
    </Container>
    </Container>
  );
};

export default ResetPassword;
