import { createSlice } from '@reduxjs/toolkit'

const adminSlice = createSlice({
    name: 'admin',
    initialState : {
        clubAdmin: null
    },
    reducers: {
        fillClubAdmin: (state, action) => {
            state.clubAdmin = action.payload.data;
        },
        updateClubAdmin: (state, action) => {
            state.clubAdmin = {
                ...state.clubAdmin, // Spread existing state to preserve other fields
                ...action.payload // Apply updates from the action payload
              };
        },
        logoutAdmin: (state, action) => {
            state.clubAdmin = null;
        },
    }
})

export const { fillClubAdmin, updateClubAdmin, logoutAdmin} = adminSlice.actions
export default adminSlice.reducer;