import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'auth',
    initialState : {
        authData: null 
    },
    reducers: {
        login: (state, action) => {
            localStorage.setItem('profile', JSON.stringify({_id:action?.payload.result._id, token: action?.payload.token}));
            state.authData = action?.payload.result;
        },
        logoutAuth: (state, action) => {
            localStorage.clear();
            state.authData = null;
        },
        getUserData: (state, action) => {
            state.authData = action?.payload;
        },
        clearUser: (state, action) =>{
            state.authData = null;
        },
        updateUserPhotos: (state, action) => {
            state.authData = {
                ...state.authData, 
                ...action.payload
              };
        }
    }
})

export const { login, logoutAuth, getUserData, clearUser, updateUserPhotos} = authSlice.actions
export default authSlice.reducer;