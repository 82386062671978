import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button, TextField, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, Typography, Box,
  Paper, Grid, CircularProgress, Select, MenuItem,
  FormControl, InputLabel
} from '@mui/material';
import * as api from '../../api/index.js';
import { updateSeat } from '../../reducers/seats.js';
import { updateSeatReservation } from '../../reducers/reservation.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateSeatAvailability } from '../../reducers/seatAvailability.js';
import { format, parse } from 'date-fns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

const Availability = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clubId } = useParams();
  const user = useSelector((state) => state.authReducer.authData);
  const seats = useSelector((state) => state.seatReducer.seats);
  const seatAvailabilities = useSelector((state) => state.seatAvailabilityReducer.seatAvailabilities);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSeatsByType, setSelectedSeatsByType] = useState({});
  const [selectedTimeSlots, setSelectedTimeSlots] = useState({});
  const [loading, setLoading] = useState({ seatInfo: true, seatAvailabilities: true, discounts: true });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [discounts, setDiscounts] = useState([]);
  const [selectedDiscounts, setSelectedDiscounts] = useState({});
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [selectedDiscountTerms, setSelectedDiscountTerms] = useState('');
  const [generalTermsModalOpen, setGeneralTermsModalOpen] = useState(false);

  useEffect(() => {
    const fetchSeatInformation = async () => {
      try {
        const response = await api.getSeatsInfo(clubId);
        dispatch(updateSeat(response.data));
        setLoading(prev => ({ ...prev, seatInfo: false }));
      } catch (error) {
        console.error("Error fetching seat details:", error);
        setLoading(prev => ({ ...prev, seatInfo: false }));
      }
    };

    const fetchSeatAvailabilities = async () => {
      try {
        const startDate = new Date();
        const endDate = new Date();
        endDate.setDate(startDate.getDate() + 8);
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');
        const payload = {
          ClubId: clubId,
          startDate: formattedStartDate,
          endDate: formattedEndDate
        };
        const response = await api.getSeatsAvailability(payload);
        dispatch(updateSeatAvailability(response.data));
        setLoading(prev => ({ ...prev, seatAvailabilities: false }));
      } catch (error) {
        console.error("Error fetching seat availabilities:", error);
      }
    };

    const fetchDiscounts = async () => {
      try {
        const response = await api.getDiscounts(clubId);
        setDiscounts(response.data);
        setLoading(prev => ({ ...prev, discounts: false }));
      } catch (error) {
        console.error("Error fetching discounts:", error);
        setLoading(prev => ({ ...prev, discounts: false }));
      }
    };
    fetchSeatInformation();
    fetchSeatAvailabilities();
    fetchDiscounts();
  }, [clubId]);

  const handleProceedToPay = async () => {
    if (!user) {
      setAlertMessage('You need to be logged in to proceed.');
      setAlertOpen(true);
    } else {
      let formattedDate = format(selectedDate, 'yyyy-MM-dd');
      try {
        const seatIdsToName = seatAvailabilities[formattedDate].reduce((acc, current) => {
          const seatId = current.seatId;
          const selectedTimeSlot = selectedTimeSlots[seatId];
          if (selectedTimeSlot) {
            const slot = current.timeSlots.find(slot => slot.startTime === selectedTimeSlot);
            if (slot) {
              acc[seatId] = { seatName: current.title, price: slot.price.$numberDecimal, timeSlot: selectedTimeSlot };
            }
          }
          return acc;
        }, {});
        dispatch(updateSeatReservation({ ClubId: clubId, selectedSeatsByType, seatIdsToName, date: formattedDate, selectedDiscounts: selectedDiscounts }));
        navigate(`/pay/`);
      } catch (error) {
        console.error("Seat not available, please reload:", error);
      }
    }
  };

  const handleTimeSlotChange = (seatId, timeSlot) => {
    // setSelectedTimeSlots((prev) => ({ ...prev, [seatId]: timeSlot }));
    // setSelectedDiscounts((prev) => ({ ...prev, [seatId]: null }));

    // This will only allow for one seat type selection
    setSelectedTimeSlots({ [seatId]: timeSlot });  // Clear previous selections
    setSelectedSeatsByType({});  // Clear previous seat selections
    setSelectedDiscounts({ [seatId]: null });  // Clear previous discount selections
  };

  const handlePeopleChange = (seatId, number) => {
    // setSelectedSeatsByType(prev => ({
    //   ...prev,
    //   [seatId]: { ...prev[seatId], numberOfPeople: number, numberOfReservations: 1 }
    // }));

    setSelectedSeatsByType({ [seatId]: { numberOfPeople: number, numberOfReservations: 1 } });  // Clear previous selections and set the new one
  };

  const handleReservationsChange = (seatId, number) => {
    const seat = seats.find(seat => seat._id === seatId);
    // if (seat) {
    //   setSelectedSeatsByType(prev => ({
    //     ...prev,
    //     [seatId]: { ...prev[seatId], numberOfReservations: number, numberOfPeople: seat.maxPeople }
    //   }));
    // }

    if (seat) {
      setSelectedSeatsByType({ [seatId]: { numberOfReservations: number, numberOfPeople: seat.maxPeople } });  // Clear previous selections and set the new one
    }
  };

  const getDiscountForTimeSlot = (seatId, timeSlot) => {
    const selectedDiscounts = discounts.filter(discount => 
      discount.seatDiscounts.some(seatDiscount => 
        seatDiscount.seatId.toString() === seatId.toString() && 
        seatDiscount.applicableDates.some(dateSlot => 
          format(new Date(dateSlot.date), 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd') &&
          dateSlot.timeSlots.includes(timeSlot)
        )
      )
    );
    return selectedDiscounts;
  };

  const handleDiscountSelect = (seatId, discount) => {
    setSelectedDiscounts((prev) => ({ ...prev, [seatId]: discount }));
  };

 const handleTermsOpen = (discount) => {
  const terms = (
    <>
      Name: {discount.name}<br />
      Percentage: {discount.percentage}%<br />
      Terms: {discount.terms}<br />
      Minimum Amount: {discount.minAmount ? discount.minAmount : 'N/A'}<br />
      Maximum Amount: {discount.maxAmount ? discount.maxAmount : 'N/A'}
    </>
  );
  setSelectedDiscountTerms(terms);
  setTermsModalOpen(true);
};

const handleGeneralTermsOpen = () => {
  setGeneralTermsModalOpen(true);
};

  return (
    <Box sx={{ display: 'flex', mt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Select a date"
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue);
              }}
              shouldDisableDate={(date) => date < new Date(new Date().setHours(0, 0, 0, 0)) || date > new Date(new Date().setDate(new Date().getDate() + 7))}
              slotProps={{
                textField: {
                  variant: "outlined",
                  sx: {
                    backgroundColor: "black",
                    '& .MuiInputBase-input': {
                      color: 'white', // Text color
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#9c27b0', // Border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#9c27b0', // Border color on hover
                    },
                    '& .MuiInputLabel-root': { // Label color
                      color: '#9c27b0',
                    },
                  },
                }
              }}
            />
          </LocalizationProvider>
          <Button variant="outlined" onClick={handleGeneralTermsOpen} sx={{ mt: 2, color: '#9c27b0', borderColor: '#9c27b0' }}>
                Terms and Conditions
              </Button>
        </Grid>
        <Grid item xs={12} sm={8}>
          {(loading.seatInfo || loading.seatAvailabilities || loading.discounts) ?
            <CircularProgress />
            :
            <>
              {seatAvailabilities[format(selectedDate, 'yyyy-MM-dd')]?.length > 0 ? (
                seatAvailabilities[format(selectedDate, 'yyyy-MM-dd')].map((availability) => (
                  <Paper key={availability.seatId} sx={{ p: 3, mb: 2 }}>
                    <Typography sx={{ mb: 2 }} variant="h4">{availability.title}</Typography>
                    <Typography sx={{ mb: 2 }} variant="h6">{availability.desc}</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}>
                      {availability.timeSlots.map((slot) => (
                        <Button
                          key={slot.startTime}
                          variant={selectedTimeSlots[availability.seatId] === slot.startTime ? "contained" : "outlined"}
                          onClick={() => handleTimeSlotChange(availability.seatId, slot.startTime)}
                          sx={{
                            m: 1,
                            borderRadius: '50px',
                            backgroundColor: selectedTimeSlots[availability.seatId] === slot.startTime ? "#9c27b0" : "transparent",
                            color: selectedTimeSlots[availability.seatId] === slot.startTime ? "white" : "inherit",
                          }}
                        >
                          {format(parse(slot.startTime, 'HH:mm', new Date()), 'hh:mm a')}
                        </Button>
                      ))}
                    </Box>
                    {selectedTimeSlots[availability.seatId] && (
                      <>
                        <Typography sx={{ mb: 2 }} variant="body1">Maximum Number of people per reservation: {availability.maxPeople}</Typography>
                        <Typography variant="body1">Available: {availability.timeSlots.find(slot => slot.startTime === selectedTimeSlots[availability.seatId]).availableSeats}</Typography>
                        <Typography variant="body1">
                          Reservation Fees: ₹{ 
                            availability.timeSlots.find(slot => slot.startTime === selectedTimeSlots[availability.seatId]).price.$numberDecimal * 
                            (selectedSeatsByType[availability.seatId]?.numberOfPeople || 1) 
                          }
                        </Typography>

                        {availability.flexiSeats ? (
                          <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id={`number-of-people-label-${availability.seatId}`}>Number of People</InputLabel>
                            <Select
                              labelId={`number-of-people-label-${availability.seatId}`}
                              id={`number-of-people-${availability.seatId}`}
                              value={selectedSeatsByType[availability.seatId]?.numberOfPeople || 0}
                              label="Number of People"
                              onChange={(e) => {
                                const newSelectedNumber = Math.min(parseInt(e.target.value, 10), availability.maxPeople);
                                handlePeopleChange(availability.seatId, newSelectedNumber);
                              }}
                            >
                              {[...Array(availability.maxPeople + 1).keys()].map((num) => (
                                <MenuItem key={num} value={num}>{num}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <TextField
                            type="number"
                            label="Number of Reservations"
                            value={selectedSeatsByType[availability.seatId]?.numberOfReservations || 0}
                            onChange={(e) => {
                              const newSelectedNumber = Math.min(parseInt(e.target.value, 10), availability.timeSlots.find(slot => slot.startTime === selectedTimeSlots[availability.seatId])?.availableSeats);
                              handleReservationsChange(availability.seatId, newSelectedNumber);
                            }}
                            InputProps={{ inputProps: { min: 0, max: availability.timeSlots.find(slot => slot.startTime === selectedTimeSlots[availability.seatId])?.availableSeats } }}
                            sx={{ mt: 3, mb: 2 }}
                            fullWidth
                          />
                        )}
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
                        {getDiscountForTimeSlot(availability.seatId, selectedTimeSlots[availability.seatId]).length > 0 && (
                          <Typography sx={{ width: '100%', mb: 1 }} variant="h6">Discounts Available:</Typography>
                        )}
                          {getDiscountForTimeSlot(availability.seatId, selectedTimeSlots[availability.seatId]).map((discount) => (
                            <Box key={discount._id} sx={{ m: 1, position: 'relative' }}>
                              <Button
                                variant={selectedDiscounts[availability.seatId] === discount ? "contained" : "outlined"}
                                onClick={() => handleDiscountSelect(availability.seatId, discount)}
                                sx={{
                                  width: 60,
                                  height: 60,
                                  borderRadius: '10px',
                                  backgroundColor: selectedDiscounts[availability.seatId] === discount ? "#9c27b0" : "transparent",
                                  color: selectedDiscounts[availability.seatId] === discount ? "white" : "inherit",
                                }}
                              >
                                {discount.percentage}%
                              </Button>
                              <Button
                                variant="text"
                                onClick={() => handleTermsOpen(discount)}
                                sx={{
                                  position: 'absolute',
                                  top: -5,
                                  right: -5,
                                  minWidth: 24,
                                  height: 24,
                                  borderRadius: '50%',
                                  backgroundColor: '#9c27b0',
                                  color: 'white',
                                  fontSize: 12,
                                  p: 0
                                }}
                              >
                                i
                              </Button>
                            </Box>
                          ))}
                        </Box>
                      </>
                    )}
                  </Paper>
                ))
              ) : (
                <Typography>No seats available for this date.</Typography>
              )}
            </>
          }
          {Object.keys(selectedSeatsByType).length > 0 && (
            <Button variant="contained" onClick={handleProceedToPay} sx={{ mt: 2 }}>
              Proceed to Pay
            </Button>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Login"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        aria-labelledby="terms-dialog-title"
        aria-describedby="terms-dialog-description"
      >
        <DialogTitle id="terms-dialog-title">{"Discount Terms and Conditions"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="terms-dialog-description">
            {selectedDiscountTerms}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTermsModalOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={generalTermsModalOpen}
        onClose={() => setGeneralTermsModalOpen(false)}
        aria-labelledby="general-terms-dialog-title"
        aria-describedby="general-terms-dialog-description"
      >
        <DialogTitle id="general-terms-dialog-title">{"General Terms and Conditions"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="general-terms-dialog-description">
            Please note the following terms and conditions for reservations and discounts:
            <ul>
              <li>Booking fees will be adjusted in the final bill.</li>
              <li>Discounts are only applicable under the following conditions:
                <ul>
                  <li>The reservation is made for the exact number of people as specified.</li>
                  <li>The reservation is completed and confirmed prior to the event date and time.</li>
                  <li>Payments are processed exclusively through the Nightly platform by accessing your profile and selecting past reservations.</li>
                </ul>
              </li>
              <li>All sales are final and non-refundable</li>
              <li>All reservations are subject to availability and confirmation.</li>
              <li>Please bring a valid ID and your reservation confirmation email for entry.</li>
              <li>The establishment reserves the right to deny entry to any guest not complying with the dress code or policies.</li>
              <li>All guests must be of legal age. Denial based on age are not subject to refunds from Nightly.</li>
              <li>By proceeding with the reservation, you agree to all the above terms and conditions.</li>
            </ul>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setGeneralTermsModalOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Availability;
