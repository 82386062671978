import React, { useState, useEffect } from 'react';
import { Box, AppBar, Avatar, Typography, Toolbar, Button, Menu, MenuItem, IconButton, Dialog, DialogTitle, DialogContent  } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {jwtDecode} from 'jwt-decode';
import { getUserData } from '../../reducers/auth';
import './styles.css'; 
import { logoutAuth } from '../../reducers/auth.js';
import { logoutAdmin } from '../../reducers/admin.js';
import * as api from '../../api/index.js';
import logo from "../../images/logo.png"
import Search from '../Home/Search.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { logoutPastReservation } from '../../reducers/pastReservationUser.js';
import { logoutSeatReservation } from '../../reducers/reservation.js';
 
const NavBar = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [user, setUser] = useState(useSelector((state)=>state.authReducer.authData));
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const currentCity = useSelector(state => state.cityReducer);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        verifyToken() 
    }, [location]);
 
    useEffect(() => {
        const fetchUserData = async () => {
            const storedData = JSON.parse(localStorage.getItem('profile'));
            if (storedData?.token) {
                // Assuming `api.fetchUserInfo` is a function that sends the token to the backend
                // and returns user information if the token is valid.
                try {
                    const response  = await api.getUserData({token: storedData.token, id: storedData._id});
                    dispatch(getUserData(response.data)); 
                    setUser(response.data); 
                } catch (error) {
                    console.log(error);
                    logOut()
                }
            }
        };
        if (!user) {
            fetchUserData();
        }
    }, [user]);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const verifyToken = () => {
        const token =  JSON.parse(localStorage.getItem('profile'))?.token
        if(!token) {
            return false;
        }
        else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.exp * 1000 < new Date().getTime()) logOut();
        }
        return true;
    }

    const logOut = () => {
        dispatch(logoutAuth());
        dispatch(logoutAdmin());
        dispatch(logoutSeatReservation())
        dispatch(logoutPastReservation())
        navigate("/");
        setUser(null);
        handleClose();
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const auth = () => {
        navigate('/auth/login');
        handleClose();
    };

    return (
        <div>
        <AppBar position="fixed" sx={{ backgroundColor: '#000'}}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={logo} alt="Nightly Logo" style={{ width: 40, height: 40, marginRight: 8 }} />
                <Typography variant="h4" className="neon-purple" component={Link} to="/" sx={{ color: 'inherit', textDecoration: 'none', flexGrow: 1 }}>
                    NIGHTLY
                </Typography>
            </Box>
                {verifyToken()? (
                    <>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar alt={user?.firstName} sx={{ width: 30, height: 30 }} />
                            <Typography sx={{ml:2}} className="neon-purple" variant="h6" color="inherit">
                                Hi, {user?.firstName} 
                            </Typography>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                            sx={{
                                '& .MuiPaper-root': { // Custom styling for the menu
                                    backgroundColor: '#333',
                                    color: '#fff',
                                },
                                '& .MuiMenuItem-root': { // Custom styling for menu items
                                    '&:hover': {
                                        backgroundColor: '#555',
                                    },
                                },
                            }}
                        >
                            <MenuItem className="neon-purple">Nightly Points: {user?.points || 0}</MenuItem>
                            <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
                            <MenuItem onClick={logOut}>Logout</MenuItem>
                        </Menu>
                    </>
                ) : (
                    <Button onClick={auth} >
                        Log In
                    </Button>
                )}
            </Toolbar>
            <Box sx={{ height: '1px', backgroundColor: 'white', width: '100vw' }} />
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={handleOpenModal}>
                    <Typography variant={isMobile?"body2":"h6"} sx={{ color: '#9c27b0',textDecoration: 'underline', m: 2, ml:3  }}>
                        {currentCity.cityName || 'Select City'}
                    </Typography>
            </Box>
            <Dialog 
                open={modalOpen}
                onClose={handleCloseModal}
                sx={{
                    '& .MuiDialog-paper': { // Targeting the dialog's paper element
                        backgroundColor: '#000', // Dark background for the dialog
                        color: '#fff', // White text color
                        borderRadius: '8px', // Rounded corners
                    },
                }}>
                <DialogTitle sx={{ textAlign: 'center' }}>Select Your City</DialogTitle>
                <DialogContent
                    dividers={true} // Adds a divider between the content and actions (optional)
                    sx={{
                        maxHeight: '60vh', // Limits the content area to 60% of the viewport height
                        overflowY: 'auto' // Enables scrolling within the content area
                    }}>
                    <Search onClose={handleCloseModal}/> {/* Embedding the Search component for city selection */}
                </DialogContent>
            </Dialog>
        </AppBar>
        </div>
    );
};

export default NavBar;
