import { createSlice } from '@reduxjs/toolkit'

const seatReservationSlice = createSlice({
    name: 'seatReservation',
    initialState : {
        ClubId: "",
        selectedSeatsByType: {},
        seatIdsToName: {},
        selectedDiscounts: {},
        date: "",
    },
    reducers: {
        updateSeatReservation: (state, action) => {
            state.ClubId = action.payload.ClubId;
            state.selectedSeatsByType = action.payload.selectedSeatsByType;
            state.seatIdsToName = action.payload.seatIdsToName
            state.selectedDiscounts = action.payload.selectedDiscounts
            state.date = action.payload.date
        },
        logoutSeatReservation: (state, action) => {
            state.ClubId = "";
            state.selectedSeatsByType = {};
            state.selectedDiscounts = {};
            state.seatIdsToName = {};
            state.date = "";
        }
    }
})

export const { updateSeatReservation, logoutSeatReservation} = seatReservationSlice.actions
export default seatReservationSlice.reducer;