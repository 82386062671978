import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Card, Chip, Grid, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Snackbar, Alert } from '@mui/material';
import * as api from '../../api/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateDiscount, editDiscount, removeDiscount } from '../../reducers/discounts.js';
import { updateSeat } from '../../reducers/seats.js';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const Discounts = () => {
    const dispatch = useDispatch();
    const discounts = useSelector((state) => state.discountReducer.discounts);
    const seats = useSelector((state) => state.seatReducer.seats);
    const user = useSelector((state) => state.authReducer.authData);
    const { _id } = useSelector(state => state.adminReducer.clubAdmin);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState({ open: false, message: '' });
    const [currentDiscount, setCurrentDiscount] = useState({
        name: '',
        percentage: '',
        minAmount: '',
        maxAmount: '',
        terms: '',
        seatDiscounts: []
    });
    const [timeSlot, setTimeSlot] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedSeat, setSelectedSeat] = useState('');

    useEffect(() => {
        fetchDiscounts();
        fetchSeats();
    }, [_id]);

    const fetchDiscounts = async () => {
        try {
            const response = await api.getDiscounts(_id);
            dispatch(updateDiscount(response.data));
        } catch (error) {
            console.error("Failed to fetch discounts:", error);
        }
    };

    const fetchSeats = async () => {
        try {
            const response = await api.getSeatsInfo(_id);
            dispatch(updateSeat(response.data));
        } catch (error) {
            console.error("Failed to fetch seats:", error);
        }
    };

    const handleOpen = (discount) => {
        setCurrentDiscount(discount ? JSON.parse(JSON.stringify(discount)) : {
            name: '',
            percentage: '',
            minAmount: '',
            maxAmount: '',
            terms: '',
            seatDiscounts: []
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentDiscount(prev => ({ ...prev, [name]: value }));
    };

    const handleAddTimeSlot = (seatIndex, dateIndex) => {
        if (timeSlot) {
            setCurrentDiscount(prev => {
                const updatedSeatDiscounts = [...prev.seatDiscounts];
                const updatedDates = [...updatedSeatDiscounts[seatIndex].applicableDates];
                updatedDates[dateIndex] = {
                    ...updatedDates[dateIndex],
                    timeSlots: [...updatedDates[dateIndex].timeSlots, timeSlot]
                };
                updatedSeatDiscounts[seatIndex] = {
                    ...updatedSeatDiscounts[seatIndex],
                    applicableDates: updatedDates
                };
                return {
                    ...prev,
                    seatDiscounts: updatedSeatDiscounts
                };
            });
            setTimeSlot('');
        }
    };

    const handleAddDate = () => {
        if (selectedDate && selectedSeat) {
            setCurrentDiscount(prev => {
                const updatedSeatDiscounts = prev.seatDiscounts.map(sd => {
                    if (sd.seatId === selectedSeat) {
                        const dateExists = sd.applicableDates.some(ad => ad.date === selectedDate);
                        if (!dateExists) {
                            return {
                                ...sd,
                                applicableDates: [...sd.applicableDates, { date: selectedDate, timeSlots: [] }]
                            };
                        }
                    }
                    return sd;
                });

                if (!updatedSeatDiscounts.some(sd => sd.seatId === selectedSeat)) {
                    updatedSeatDiscounts.push({
                        seatId: selectedSeat,
                        applicableDates: [{ date: selectedDate, timeSlots: [] }]
                    });
                }

                return {
                    ...prev,
                    seatDiscounts: updatedSeatDiscounts
                };
            });
        }
    };

    const handleRemoveTimeSlot = (seatIndex, dateIndex, slotIndex) => {
        setCurrentDiscount(prev => {
            const updatedSeatDiscounts = [...prev.seatDiscounts];
            const updatedDates = [...updatedSeatDiscounts[seatIndex].applicableDates];
            updatedDates[dateIndex] = {
                ...updatedDates[dateIndex],
                timeSlots: updatedDates[dateIndex].timeSlots.filter((_, index) => index !== slotIndex)
            };
            if (updatedDates[dateIndex].timeSlots.length === 0) {
                updatedDates.splice(dateIndex, 1);
            }
            if (updatedDates.length === 0) {
                updatedSeatDiscounts.splice(seatIndex, 1);
            } else {
                updatedSeatDiscounts[seatIndex] = {
                    ...updatedSeatDiscounts[seatIndex],
                    applicableDates: updatedDates
                };
            }
            return {
                ...prev,
                seatDiscounts: updatedSeatDiscounts
            };
        });
    };

    const handleRemoveSeatType = (seatIndex) => {
        setCurrentDiscount(prev => ({
            ...prev,
            seatDiscounts: prev.seatDiscounts.filter((_, index) => index !== seatIndex)
        }));
        handleSave()
    };

    const handleSave = async () => {
        try {
            let response;
            if (currentDiscount._id) {
                response = await api.updateDiscount(currentDiscount._id, user._id, _id, currentDiscount);
                dispatch(editDiscount(response.data));
            } else {
                response = await api.createDiscount(_id, user._id, currentDiscount);
                dispatch(editDiscount(response.data));
            }
            handleClose();
        } catch (error) {
            console.error("Failed to save discount:", error);
        }
    };

    const handleDelete = async (discountId) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this discount? This action cannot be undone.");
        if (isConfirmed) {
            try {
                await api.deleteDiscount(discountId, user._id, _id);
                dispatch(removeDiscount(discountId));
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setError({ open: true, message: 'Cannot delete the discount as it is applied to existing reservations. Please contact us for help.' });
                } else {
                    console.error("Failed to delete discount:", error);
                }
            }
        }
    };

    const handleErrorClose = () => {
        setError({ open: false, message: '' });
    };

    return (
        <Box sx={{ mt: 2, color: 'white', p: 2, width: "100%" }}>
            <IconButton
                component="label" onClick={() => handleOpen(null)} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2,
                }}
            >
                <AddIcon sx={{ fontSize: '3rem', color: '#9c27b0' }} />
                <Typography variant="body1" sx={{ mt: 1, color: '#9c27b0' }}>Add New Discount</Typography>
            </IconButton>
            {discounts.map((discount) => (
                <Card
                    key={discount._id}
                    sx={{ bgcolor: 'grey.100', color: 'grey.900', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, p: 2 }}
                >
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h5">{discount.name}</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>{discount.terms}</Typography>
                        <Typography variant="body2">Percentage: {discount.percentage}%</Typography>
                        <Typography variant="body2">Minimum Amount: {discount.minAmount || 'N/A'}</Typography>
                        <Typography variant="body2">Maximum Amount: {discount.maxAmount || 'N/A'}</Typography>
                        <Typography variant="body2">Applicable Dates:</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                            {discount.seatDiscounts.map((seatDiscount, seatIndex) => (
                                <Box key={seatDiscount._id} sx={{ width: '100%' }}>
                                    <Typography variant="body2" sx={{ color: 'black' }}>Seat: {seats.find(seat => seat._id === seatDiscount.seatId)?.title || seatDiscount.seatId}</Typography>
                                    {seatDiscount.applicableDates.map((date, dateIndex) => (
                                        <Box key={dateIndex} sx={{ display: 'flex', alignItems: 'center', m: 0.5 }}>
                                            <Chip
                                                label={`${date.date.split('T')[0]}, Time Slots: ${date.timeSlots.join(', ')}`}
                                                sx={{ m: 0.5, backgroundColor: '#9c27b0', color: 'white' }}
                                            />
                                            <IconButton size="small" onClick={() => handleRemoveTimeSlot(seatIndex, dateIndex, -1)} sx={{ ml: 1 }}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    ))}
                                    <Button onClick={() => handleRemoveSeatType(seatIndex)} sx={{ color: 'red', mt: 1 }}>
                                        Remove Seat Type
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box>
                        <Button onClick={() => handleOpen(discount)} sx={{ color: '#9c27b0', mr: 1 }}>
                            <EditIcon />
                        </Button>
                        <Button onClick={() => handleDelete(discount._id)} sx={{ color: 'red' }}>
                            <DeleteIcon />
                        </Button>
                    </Box>
                </Card>
            ))}

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{currentDiscount?._id ? 'Edit Discount' : 'Add New Discount'}</DialogTitle>
                <DialogContent>
                    <TextField autoFocus margin="dense" id="name" name="name" label="Discount Name" type="text" fullWidth value={currentDiscount?.name || ''} onChange={handleChange} />
                    <TextField margin="dense" id="percentage" name="percentage" label="Discount Percentage" type="number" fullWidth value={currentDiscount?.percentage || ''} onChange={handleChange} />
                    <TextField margin="dense" id="minAmount" name="minAmount" label="Minimum Amount" type="number" fullWidth value={currentDiscount?.minAmount || ''} onChange={handleChange} />
                    <TextField margin="dense" id="maxAmount" name="maxAmount" label="Maximum Amount" type="number" fullWidth value={currentDiscount?.maxAmount || ''} onChange={handleChange} />
                    <TextField margin="dense" id="terms" name="terms" label="Terms" type="text" fullWidth value={currentDiscount?.terms || ''} onChange={handleChange} />

                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" sx={{ color: 'black', mb: 1 }}>Applicable Dates</Typography>
                        <TextField
                            label="Select Date"
                            type="date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Select Seat"
                            select
                            value={selectedSeat}
                            onChange={(e) => setSelectedSeat(e.target.value)}
                            fullWidth
                            sx={{ mb: 2 }}
                        >
                            {seats.map(seat => (
                                <MenuItem key={seat._id} value={seat._id}>{seat.title}</MenuItem>
                            ))}
                        </TextField>
                        <Button onClick={handleAddDate} sx={{ mt: 2, backgroundColor: "#9c27b0", color: "white" }}>Add Date</Button>
                        {currentDiscount?.seatDiscounts.map((seatDiscount, seatIndex) => (
                            <Box key={seatDiscount.seatId} sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ color: 'black' }}>Seat: {seats.find(seat => seat._id === seatDiscount.seatId)?.title || seatDiscount.seatId}</Typography>
                                {seatDiscount.applicableDates.map((date, dateIndex) => (
                                    <Box key={dateIndex}>
                                        <Typography variant="body2" sx={{ color: 'black' }}>Date: {date.date.split('T')[0]}</Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                                            {date.timeSlots.map((slot, slotIndex) => (
                                                <Box key={slotIndex} sx={{ display: 'flex', alignItems: 'center', m: 0.5 }}>
                                                    <Chip
                                                        label={`${slot}`}
                                                        sx={{ backgroundColor: '#9c27b0', color: 'white' }}
                                                    />
                                                    <IconButton size="small" onClick={() => handleRemoveTimeSlot(seatIndex, dateIndex, slotIndex)} sx={{ ml: 1 }}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label="Add Time Slot"
                                                        type="time"
                                                        value={timeSlot}
                                                        onChange={(e) => setTimeSlot(e.target.value)}
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ step: 300 }} // 5 min
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Button onClick={() => handleAddTimeSlot(seatIndex, dateIndex)} sx={{ mt: 2, backgroundColor: "#9c27b0", color: "white" }}>Add Time Slot</Button>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>
                    <Typography variant="body2" style={{ color: 'black', marginTop: '10px' }}>
                        Discounts will be applied to the selected seat types and their respective timings.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{ color: "#9c27b0" }}>Cancel</Button>
                    <Button onClick={handleSave} sx={{ color: "#9c27b0" }}>{currentDiscount?._id ? 'Save Changes' : 'Add Discount'}</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={error.open} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    {error.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Discounts;
