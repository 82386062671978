import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const WithAccessCheck = () => {
    const accessGranted = localStorage.getItem('accessGranted') === 'true';

    if (!accessGranted) {
        // User not authorized, redirect to access code page
        return <Navigate to="/access-code" replace />;
    }

    return <Outlet />
};

export default WithAccessCheck;