import { createSlice, configureStore } from '@reduxjs/toolkit'

const clubSlice = createSlice({
    name: 'city',
    initialState : {
        id: "",
        name: "",
        city: "",
        address:  "",
        cover_stagM: 0,
        cover_stagF: 0,
        cover_couple: 0,
        email: "",
        desc: "",
        seats: [],
        images: []
    },
    reducers: {
        updateClub: (state, action) => {
            return action.payload;
        },
        editClubImages: (state, action) => {
            state.images = action.payload.images
        }
    }
})

export const { updateClub, editClubImages } = clubSlice.actions
export default clubSlice.reducer;