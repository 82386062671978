import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Card, Chip, CardActions, Grid, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, FormControlLabel } from '@mui/material';
import * as api from '../../api/index.js';
import { editSeat, removeSeat, updateSeat } from '../../reducers/seats.js';
import { useDispatch, useSelector} from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const SeatTypeSection = () => {
    const dispatch = useDispatch();
    const seats = useSelector((state) => state.seatReducer.seats);
    const user = useSelector((state) => state.authReducer.authData);
    const { _id } = useSelector(state => state.adminReducer.clubAdmin);
    const [open, setOpen] = useState(false);
    const [currentSeat, setCurrentSeat] = useState(null);
    const [timeSlot, setTimeSlot] = useState({ startTime: '', endTime: '' });

    useEffect(() => {
        // Fetch existing seat types from the backend and update state
        const fetchSeatTypes = async () => {
            try {
                // Placeholder for fetch request
                const response = await api.getSeatsInfo(_id);
                dispatch(updateSeat(response.data))
            } catch (error) {
                console.error("Failed to fetch seat types:", error);
            }
        };
        fetchSeatTypes();
    }, [_id]);

    const handleOpen = (seat) => {
        setCurrentSeat(seat || { title: '', desc: '', maxPeople: 1, flexiSeats: false, defaultPriceWeekday: { $numberDecimal: "0" }, defaultPriceWeekend: { $numberDecimal: "0" }, defaultTotalSeats: 0, defaultOpeningTimes: []});
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const updatedValue = type === 'checkbox' ? checked : name === 'defaultPriceWeekday' || name === 'defaultPriceWeekend' ? { $numberDecimal: value } : value;
        setCurrentSeat(prevSeat => ({
            ...prevSeat,
            [name]: updatedValue,
        }));
    };

    const handleAddTimeSlot = () => {
        if (timeSlot.startTime && timeSlot.endTime) {
            setCurrentSeat(prevSeat => ({
                ...prevSeat,
                defaultOpeningTimes: [...prevSeat.defaultOpeningTimes, timeSlot]
            }));
            setTimeSlot({ startTime: '', endTime: '' });
        }
    };

    const handleRemoveTimeSlot = (index) => {
        setCurrentSeat(prevSeat => ({
            ...prevSeat,
            defaultOpeningTimes: prevSeat.defaultOpeningTimes.filter((_, i) => i !== index)
        }));
    };

    const handleSave = async () => {
        let responseSeat;
        if (currentSeat._id) {
            const response = await api.updateSeatInfo(currentSeat._id, user._id, _id, {...currentSeat, ClubId: _id});
            responseSeat = response.data
        } else {
            const response = await api.createSeatInfo(_id, user._id, {...currentSeat, ClubId: _id});
            responseSeat = response.data
        }
        dispatch(editSeat(responseSeat));
        handleClose();
    };

    const handleDelete = async (seatId) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this seat? This action cannot be undone.");
        if (isConfirmed) {
          try {
            await api.deleteSeatInfo(seatId, user._id, _id); // Make sure this matches your API call
            dispatch(removeSeat(seatId)); // Dispatch action to remove seat from state
          } catch (error) {
            alert("Cannot delete seat type as there are reservations in the next week. Please contact support for assistance.")
          } 
        }
    };

    return (
        <Box sx={{ mt: 2, color: 'white', p: 2, width: "100%" }}>
            <IconButton
                component="label" onClick={() => handleOpen(null)} sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2,
                }}
            >
                <AddIcon sx={{ fontSize: '3rem', color: '#9c27b0' }} />
                <Typography variant="body1" sx={{ mt: 1, color: '#9c27b0' }}>Add New Seat</Typography>
            </IconButton>
            {seats.map((seat) => (
                <Card
                key={seat._id}
                sx={{ bgcolor: 'grey.100', color: 'grey.900', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, color: 'black', p: 2,}}
                >
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h5">{seat.title}</Typography>
                        <Typography variant="body2" sx={{mb:2}}>{seat.desc}</Typography>
                        <Typography variant="body2">Maximum Number of People In Single Reservation: {seat.maxPeople}</Typography>
                        <Typography variant="body2">Allow Booking of Less number of seats than maximum people: {seat.flexiSeats ? 'Yes' : 'No'}</Typography>
                        <Typography variant="body2">Default Reservation Price Per Person Weekday: {seat.defaultPriceWeekday.$numberDecimal}</Typography>
                        <Typography variant="body2">Default Reservation Price Per Person Weekend: {seat.defaultPriceWeekend.$numberDecimal}</Typography>
                        <Typography variant="body2">Default Number of Seats: {seat.defaultNumberOfSeats}</Typography>
                        <Typography variant="body2">Default Opening Times:</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                            {seat.defaultOpeningTimes.map((time, index) => (
                                <Chip
                                    key={index}
                                    label={`${time.startTime} - ${time.endTime}`}
                                    sx={{ m: 0.5, backgroundColor: '#9c27b0', color: 'white' }}
                                />
                            ))}
                        </Box>
                    </Box>
                    <Box>
                        <Button onClick={() => handleOpen(seat)} sx={{ color: '#9c27b0', mr: 1 }}>
                        <EditIcon />
                        </Button>
                        <Button onClick={() => handleDelete(seat._id)} sx={{ color: 'red' }}>
                        <DeleteIcon />
                        </Button>
                    </Box>
                </Card>
            ))}
            
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{currentSeat?._id ? 'Edit Seat Type' : 'Add New Seat Type'}</DialogTitle>
                <DialogContent>
                    <TextField autoFocus margin="dense" id="title" name="title" label="Title" type="text" fullWidth value={currentSeat?.title || ''} onChange={handleChange}/>
                    <TextField margin="dense" id="desc" name="desc" label="Description" type="text" fullWidth value={currentSeat?.desc || ''} onChange={handleChange}/>
                    <TextField margin="dense" id="maxPeople" name="maxPeople" label="Maximum People in one reservation" type="number" fullWidth value={currentSeat?.maxPeople || 1} onChange={handleChange}/>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={currentSeat?.flexiSeats || false}
                                onChange={handleChange}
                                name="flexiSeats"
                            />
                        }
                        label="Allow less number of seats than maximum per booking"
                    />
                    <TextField margin="dense" id="defaultPriceWeekday" name="defaultPriceWeekday" label="Default Reservation Price Per Person Weekday:" type="text" fullWidth value={currentSeat?.defaultPriceWeekday.$numberDecimal || ''} onChange={handleChange}/>
                    <TextField margin="dense" id="defaultPriceWeekend" name="defaultPriceWeekend" label="Default Reservation Price Per Person Weekend:" type="text" fullWidth value={currentSeat?.defaultPriceWeekend.$numberDecimal || ''} onChange={handleChange}/>
                    <TextField margin="dense" id="defaultNumberOfSeats" name="defaultNumberOfSeats" label="Default Number of Seats" type="number" fullWidth value={currentSeat?.defaultNumberOfSeats || ''} onChange={handleChange}/>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" sx={{ color: 'black', mb: 1 }}>Default Opening Times</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}>
                            {currentSeat?.defaultOpeningTimes.map((time, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', m: 0.5 }}>
                                    <Chip
                                        label={`${time.startTime} - ${time.endTime}`}
                                        sx={{ backgroundColor: '#9c27b0', color: 'white' }}
                                    />
                                    <IconButton size="small" onClick={() => handleRemoveTimeSlot(index)} sx={{ ml: 1 }}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Start Time"
                                    type="time"
                                    value={timeSlot.startTime}
                                    onChange={(e) => setTimeSlot(prev => ({ ...prev, startTime: e.target.value }))}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ step: 300 }} // 5 min
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="End Time"
                                    type="time"
                                    value={timeSlot.endTime}
                                    onChange={(e) => setTimeSlot(prev => ({ ...prev, endTime: e.target.value }))}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ step: 300 }} // 5 min
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Button onClick={handleAddTimeSlot} sx={{ mt: 2, backgroundColor: "#9c27b0", color: "white" }}>Add Time Slot</Button>
                    </Box>
                    <Typography variant="body2" style={{ color: 'black', marginTop: '10px' }}>
                        Setting the default price/number of seats will apply to all dates. If you need to adjust the price/ number of seats for specific dates, please visit the <b>Price/Availability</b> section.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{color:"#9c27b0"}}>Cancel</Button>
                    <Button onClick={handleSave} sx={{color:"#9c27b0"}}>{currentSeat?._id ? 'Save Changes' : 'Add Seat'}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default SeatTypeSection;
