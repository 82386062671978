import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography, Container, Paper, Button, CircularProgress, Box } from '@mui/material';
import NavBar from '../NavBar/NavBar.js'; // Adjust the path as necessary
import Footer from '../Footer/Footer'; // Adjust the path as necessary
import * as api from '../../api/index.js';
import './styles.css';

const OrderConfirmed = () => {
    const { transactionId } = useParams();
    const [reservationDetails, setReservationDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchReservation = async () => {
            try {
                const response = await api.getTransactionInfo(transactionId);
                setReservationDetails(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching reservation details:", err);
                setError('Failed to fetch reservation details.');
                setLoading(false);
            }
        };
        fetchReservation();
    }, [transactionId]);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <div>
            <NavBar />
            <Container>
                <Box sx={{ my: 15, 
                    p: 2,
                    display: 'flex',
                   flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                    <Typography className="neon-purple" sx={{color:"white", mb: 2}} variant="h4" gutterBottom textAlign="center">
                        Order Confirmed
                    </Typography>
                    <Typography sx={{color:"white", mb:5}} variant="body1" gutterBottom  textAlign="center">
                        We have sent you a confirmation email with your tickets on your registered email address. For any inquired, reach out to us!
                    </Typography>
                    {reservationDetails.map((detail, index) => (
                        <Paper key={index} elevation={3} sx={{ p: 2, mb: 2}}>
                            <Typography>Transaction ID: {detail.transactionId}</Typography>
                            <Typography>Reservation ID: {detail.reservationId}</Typography>
                            <Typography>Reservation Date: {detail.reservationFor}</Typography>
                            <Typography>Reservation Time: {detail.reservationTime}</Typography>
                            <Typography>User Name: {detail.userName}</Typography>
                            <Typography>Club: {detail.clubName}</Typography>
                            <Typography>Seat Type: {detail.seatName}</Typography>
                            <Typography>Number of Reservations: {detail.numberOfReservations}</Typography>
                            <Typography>Number of People: {detail.numberOfPeople}</Typography>
                            <Typography>Original Price: {detail.originalPrice}</Typography>
                            <Typography>Final Price: {detail.finalPrice}</Typography>
                            <Typography>Nightly Points Used: {detail.pointsUsed}</Typography>
                            <Typography>Time of Booking: {detail.bookingTime.toLocaleString()}</Typography>
                        </Paper>
                    ))}
                    <Button color="primary" component={Link} to="/">
                        Go Back to Home
                    </Button>
                </Box>
            </Container>
            <Footer />
        </div>
    );
};

export default OrderConfirmed;
