import { createSlice, configureStore } from '@reduxjs/toolkit'

const discoutSlice = createSlice({
    name: 'discount',
    initialState : {
        discounts: [],
    },
    reducers: {
        updateDiscount: (state, action) => {
            state.discounts = action.payload;
        },
        editDiscount: (state, action) => {
            const index = state.discounts.findIndex(discount => discount._id === action.payload._id);
            if (index !== -1) {
              // Seat exists, update it
              state.discounts[index] = action.payload;
            } else {
              // New seat, add it to the state
              state.discounts.push(action.payload);
            }
        },
        removeDiscount: (state, action) => {
            state.discounts = state.discounts.filter(discount => discount._id !== action.payload);
        },
    }
})

export const { updateDiscount, editDiscount, removeDiscount} = discoutSlice.actions
export default discoutSlice.reducer;