import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../api/index.js'; 
import { Button, Grid, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Card, CardMedia, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './styles.css'; 
import { updateUserPhotos } from '../../reducers/auth.js';

const ImageUploadSectionUser = () => {
    const dispatch = useDispatch();
    const userId = useSelector(state => state.authReducer.authData._id);
    const photos = useSelector(state => state.authReducer.authData.photos);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [imagesData, setImagesData] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [imageToDelete, setImageToDelete] = useState(null); 

    useEffect(() => {
        const fetchImagesData = async () => {
            try {
                const response = await api.getImagesUser(userId);
                setImagesData(response.data);
            } catch (error) {
                console.error('Failed to fetch images data:', error);
            }
        };
        fetchImagesData();
    }, [userId, refreshFlag]);

    const toggleRefreshFlag = () => setRefreshFlag(prev => !prev);

    const handleOpenDialog = (groupName, index) => {
        setAlertMessage('Are you sure you want to delete this image? This action cannot be undone.');
        setImageToDelete(imagesData[groupName][index].id);
        setAlertOpen(true);
    };
    
    const handleCloseDialog = () => {
        setAlertOpen(false);
    };
    
    const confirmDelete = async () => {
        if (imageToDelete !== null) {
            await removeProfileImage(imageToDelete);
        }
        handleCloseDialog();
    };

    const removeProfileImage = async (image) => {
        setUploading(true);
        const payload = {
            userId: userId,
            imageId: image
        }
        try {
            await api.deleteUserImage(payload);
            const updatedphotos = photos.filter((p)=>p!==image)
            dispatch(updateUserPhotos({photos: updatedphotos}))
            toggleRefreshFlag(); 
        } catch (error) {
            console.error('Failed to delete image:', error);
        } finally {
            setUploading(false);
        }
    }
    if (!imagesData || Object.keys(imagesData).length === 0) {
        return (
            <Box sx={{ 
                mt: 2, 
                color: 'white', 
                p: 2,
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', // Centers content horizontally
                justifyContent: 'center', // Centers content vertically if needed
            }}>
                <Typography align="center">You don't have any uploaded images.</Typography>
                <Typography align="center">Upload pictures, and earn Nightly Points!</Typography>
            </Box>
        )
    }
 
    return (
        <Box sx={{ mt: 2, color: 'white', p: 2,  width: "100%" }}>
            <Grid container spacing={2}>
                {Object.entries(imagesData).map(([groupName, images]) => (
                    <Box key={groupName} sx={{ width: '100%' }}>
                        <Typography variant="h4" align="center" className="neon-purple" sx={{ mt: 2, mb: 2,  pl: 2 }}>
                            {groupName}
                        </Typography>
                        <Grid container spacing={2}>
                            {images.map((image, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Card sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                    }}>
                                    {image.mediaType === 'video' ? (
                                    <video controls height="100%" style={{ objectFit: 'cover', height: 200 }}>
                                        <source src={image.url} type="video/mp4" />
                                    </video>
                                    ): (
                                    <CardMedia
                                        component="img"
                                        image={image.url}
                                        alt={`Uploaded ${index}`}
                                        sx={{ height: 200, objectFit: 'cover' }}
                                    />)}
                                    <Box sx={{ textAlign: 'center', p: 1 }}>
                                        <Typography variant="body2" sx={{ color: 'gray' }}>
                                        Uploaded at {new Date(image.uploadTime).toLocaleString()}
                                        </Typography>
                                            <Button
                                            variant="text"
                                            color="error"
                                            startIcon={<DeleteIcon />}
                                            onClick={() => handleOpenDialog(groupName, index)}
                                            sx={{ 
                                                mt: 1, // Margin top for spacing from the upload time
                                                color: 'red', // Adjust color as necessary
                                            }}
                                            >
                                            Delete
                                            </Button>
                                        </Box>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ))}
            </Grid>
            {uploading && (
                <Box sx={{
                    position: 'fixed', // Fixed position
                    top: 0, // Start from top
                    left: 0, // Start from left
                    width: '100%', // Full width
                    height: '100%', // Full height
                    display: 'flex',
                    alignItems: 'center', // Center vertically
                    justifyContent: 'center', // Center horizontally
                    backgroundColor: 'rgba(0, 0, 0, 0.5)' // Semi-transparent background
                }}>
                    <CircularProgress size={68} />
                </Box>
            )}
            <Dialog
                open={alertOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {alertMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );      
};

export default ImageUploadSectionUser;
