import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from '../../api/index.js'; 
import { Button, TextField, Container, Paper, Typography } from '@mui/material';
import NavBar from '../NavBar/NavBar.js';
import Footer from '../Footer/Footer.js';

const AccessCodePage = () => {
    const [accessCode, setAccessCode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setAccessCode(event.target.value);
        if (error) setError(''); // Clear error when user starts typing again
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent form from causing a page reload
        try {
            // API call to backend to verify the access code
            await api.verifyAccessCode({ token: accessCode });
            localStorage.setItem('accessGranted', 'true');
            navigate('/');
        } catch (error) {
            setError('Invalid Access Code. Please try again.'); // Set a user-friendly error message
        }
    };

    return (
        <Container>
         <NavBar/>
        <Container component="main" maxWidth="xs" sx={{ mt: 15 }}>
            <Paper elevation={3} sx={{ marginTop: 8, padding: 2 }}>
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                    Enter Access Code
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Access Token"
                        name="token"
                        autoComplete="token"
                        autoFocus
                        value={accessCode}
                        onChange={handleInputChange}
                        error={Boolean(error)}
                        helperText={error}
                        sx={{
                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0', // Change border color to purple on hover
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0', // Keep border color purple when focused
                            },
                            "& .MuiInputLabel-outlined": {
                                color: error ? '#f44336' : '#9c27b0', // Change label color based on error state
                            },
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        sx={{ mt: 3, mb: 2}}
                    >
                        Submit Token
                    </Button>
                </form>
            </Paper>
        </Container>
        <Footer/>
        </Container>
    );
};

export default AccessCodePage;
