import React, { useState, useEffect } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Grid, Typography, TextField, Button, Card, CardContent, CardActions, Chip, IconButton, Box } from '@mui/material';
import { format } from 'date-fns';
import * as api from '../../api/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateSeatInfoAdmin } from '../../reducers/seatInfoAdmin.js';
import DeleteIcon from '@mui/icons-material/Delete';

const PriceAvailabilitySection = () => {
    const dispatch = useDispatch();
    const [date, setDate] = useState(new Date());
    const [selectedSeatId, setSelectedSeatId] = useState(null);
    const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(null);
    const [modifications, setModifications] = useState({});
    const [newTimeSlot, setNewTimeSlot] = useState({ startTime: '', endTime: '', price: '', availableSeats: '' });
    const seats = useSelector((state) => state.seatInfoAdminReducer.seatInfoAdmin);
    const { _id } = useSelector(state => state.adminReducer.clubAdmin);

    useEffect(() => {
        fetchSeatTypes(format(date, 'yyyy-MM-dd'));
    }, [_id, dispatch]);

    const fetchSeatTypes = async (selectedDate) => {
        try {
            const response = await api.getSeatsInfoAdmin(_id, selectedDate);
            dispatch(updateSeatInfoAdmin(response.data));
        } catch (error) {
            console.error("Failed to fetch seat types:", error);
        }
    };

    const handleModificationChange = (field, value) => {
        setModifications((prevModifications) => ({
            ...prevModifications,
            [selectedSeatId]: {
                ...prevModifications[selectedSeatId],
                timeSlots: prevModifications[selectedSeatId]?.timeSlots.map((slot, index) =>
                    index === selectedTimeSlotIndex ? { ...slot, [field]: value } : slot
                ) || seats.find(seat => seat._id === selectedSeatId).timeSlots.map((slot, index) =>
                    index === selectedTimeSlotIndex ? { ...slot, [field]: value } : slot
                )
            }
        }));
    };

    const handleAddTimeSlot = (seatId) => {
        if (!newTimeSlot.startTime || !newTimeSlot.endTime || !newTimeSlot.price || !newTimeSlot.availableSeats) {
            alert('Please fill all fields before adding a new time slot.');
            return;
        }
        const existingTimeSlots = modifications[seatId]?.timeSlots || seats.find(seat => seat._id === seatId).timeSlots;
        if (existingTimeSlots.some(slot => slot.startTime === newTimeSlot.startTime)) {
            alert('Time slot with this start time already exists.');
            return;
        }
        setModifications((prevModifications) => ({
            ...prevModifications,
            [seatId]: {
                ...prevModifications[seatId],
                timeSlots: [...existingTimeSlots, { ...newTimeSlot, removed: false }]
            }
        }));
        setNewTimeSlot({ startTime: '', endTime: '', price: '', availableSeats: '' });
    };

    const handleRemoveTimeSlot = (seatId, index) => {
        setModifications((prevModifications) => {
            const updatedTimeSlots = (prevModifications[seatId]?.timeSlots || seats.find(seat => seat._id === seatId).timeSlots).map((slot, i) =>
                i === index ? { ...slot, removed: true } : slot
            );
            return {
                ...prevModifications,
                [seatId]: {
                    ...prevModifications[seatId],
                    timeSlots: updatedTimeSlots
                }
            };
        });

        if (selectedSeatId === seatId && selectedTimeSlotIndex === index) {
            setSelectedTimeSlotIndex(null);
        }
    };

    const saveChanges = async (seatId) => {
        const seatType = seats.find(seat => seat._id === seatId);
        const modificationsForSeat = modifications[seatId];
        if (!modificationsForSeat && !seatType) {
            console.error('No modifications to save for seat ID:', seatId);
            return;
        }
        const payload = {
            date: format(date, 'yyyy-MM-dd'), 
            clubId: _id,
            seatId,
            timeSlots: modificationsForSeat?.timeSlots || seatType.timeSlots
        };
        try {
            const response = await api.modifySeats(payload);
            if (response.status === 200) {
                fetchSeatTypes(format(date, 'yyyy-MM-dd'));
                setModifications(prev => ({
                    ...prev,
                    [seatId]: { timeSlots: null },
                }));
                setSelectedTimeSlotIndex(null);
            }
        } catch (error) {
            console.error('Failed to save modifications:', error);
        }
    };

    const getSlotValue = (seatId, slotIndex, field) => {
        const value = modifications[seatId]?.timeSlots?.[slotIndex]?.[field] || seats.find(seat => seat._id === seatId)?.timeSlots[slotIndex]?.[field];
        return typeof value === 'object' ? value.$numberDecimal || '' : value || '';
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}>
                <DatePicker
                    label="Select Date"
                    value={date}
                    onChange={(newValue) => {
                        setDate(newValue);
                        fetchSeatTypes(format(newValue, 'yyyy-MM-dd'));
                    }}
                    shouldDisableDate={(date) => date < new Date(new Date().setHours(0, 0, 0, 0))}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{
                        textField: {
                            marginBottom: '20px',
                            variant: "outlined",
                            sx: {
                                backgroundColor: "black",
                                '& .MuiInputBase-input': {
                                    color: 'white',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#9c27b0',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#9c27b0',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#9c27b0',
                                },
                            },
                        }
                    }}
                />
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    {seats.map((seatType) => (
                        <Grid item key={seatType._id} sx={{ width: '100%', height: 'auto', mb: 5 }}>
                            <Card sx={{ bgcolor: 'grey.100', color: 'grey.900', p: 2, height: '100%', mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        {seatType.title}
                                    </Typography>

                                    <Typography variant="body1" gutterBottom>
                                        Description: {seatType.desc}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        Max People: {seatType.maxPeople}
                                    </Typography>

                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                                        {(modifications[seatType._id]?.timeSlots || seatType.timeSlots).map((time, index) => (
                                            !time.removed && (
                                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', m: 0.5 }}>
                                                    <Chip
                                                        label={`${time.startTime} - ${time.endTime}`}
                                                        sx={{
                                                            backgroundColor: selectedSeatId === seatType._id && selectedTimeSlotIndex === index ? '#9c27b0' : 'grey',
                                                            color: 'white',
                                                            m: 0.5
                                                        }}
                                                        onClick={() => {
                                                            setSelectedSeatId(seatType._id);
                                                            setSelectedTimeSlotIndex(index);
                                                        }}
                                                    />
                                                    <IconButton size="small" onClick={() => handleRemoveTimeSlot(seatType._id, index)} sx={{ ml: 1 }}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            )
                                        ))}
                                    </Box>

                                    {selectedSeatId === seatType._id && selectedTimeSlotIndex !== null && (
                                        <Box sx={{ mt: 2 }}>
                                            <TextField
                                                label="Modify Price"
                                                type="number"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                margin="normal"
                                                value={getSlotValue(seatType._id, selectedTimeSlotIndex, 'price')}
                                                onChange={(e) => handleModificationChange("price", e.target.value)}
                                            />
                                            <TextField
                                                label="Modify Seats Available"
                                                type="number"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                margin="normal"
                                                value={getSlotValue(seatType._id, selectedTimeSlotIndex, 'availableSeats')}
                                                onChange={(e) => handleModificationChange("availableSeats", e.target.value)}
                                            />
                                        </Box>
                                    )}

                                    <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>Add New Time Slot</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Start Time"
                                                type="time"
                                                value={newTimeSlot.startTime}
                                                onChange={(e) => setNewTimeSlot(prev => ({ ...prev, startTime: e.target.value }))}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ step: 300 }} // 5 min
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="End Time"
                                                type="time"
                                                value={newTimeSlot.endTime}
                                                onChange={(e) => setNewTimeSlot(prev => ({ ...prev, endTime: e.target.value }))}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ step: 300 }} // 5 min
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Price"
                                                type="number"
                                                value={newTimeSlot.price}
                                                onChange={(e) => setNewTimeSlot(prev => ({ ...prev, price: e.target.value }))}
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Available Seats"
                                                type="number"
                                                value={newTimeSlot.availableSeats}
                                                onChange={(e) => setNewTimeSlot(prev => ({ ...prev, availableSeats: e.target.value }))}
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button onClick={() => handleAddTimeSlot(seatType._id)} sx={{ mt: 2 }}>Add Time Slot</Button>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => saveChanges(seatType._id)}>
                                        Save Changes
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </LocalizationProvider>
    );
};

export default PriceAvailabilitySection;
