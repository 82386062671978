
import React, { useState, useEffect } from 'react';
import * as api from '../../api/index.js';
import { TextField, Box, Typography, Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { update } from '../../reducers/cityFetcher.js';

const Search = ({ onClose }) => {
    const [cities, setCities] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // Function to fetch cities from the backend
        const fetchCities = async () => {
            try {
                const response = await api.getCities();
                setCities(response.data); // Assuming the backend returns an array of city names
                dispatch(update(response.data))
            } catch (error) {
                console.error("Error fetching cities:", error);
            }
        };

        fetchCities();
    }, []);


    const handleCitySelect = (event, city) => {
        navigate(`/cities/${city._id}`); // Navigate to the city's page
        if (onClose) {
            onClose();
        }
    };
 
    return (
        <div style={{ textAlign: 'center', backgroundSize: 'cover' }}>
            <Box sx={{ textAlign: 'center', color: '#fff', display: "flex", justifyContent: "center", flexDirection: 'column', alignItems:"center"}}>
                <Autocomplete
                    sx = {{ 
                    width: 300,  
                    borderRadius: '4px', 
                    mb: 10,
                    }}
                    options={cities}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => 
                    <TextField {...params} label="Choose a city" variant="outlined" 
                        sx = {{
                            '& .MuiInputBase-input': {
                                color: 'white', // Text color
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0', // Border color
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0', // Border color on hover
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0', // Border color when focused
                              },
                              '& .MuiInputLabel-root': { // Label color
                                color: '#9c27b0',
                              },
                        }}
                    />}
                    onChange={(event, city) => handleCitySelect(event, city)}
                />
            </Box>
        </div>
    )

}

export default Search