import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as api from '../../api/index.js';
import NavBar from '../NavBar/NavBar.js';
import './styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { updateClubsInCity } from '../../reducers/city.js';
import { Card, CardContent, CardMedia, Typography, Grid, Box, CardActionArea, useMediaQuery, useTheme } from '@mui/material';
import Footer from '../Footer/Footer.js';


const CityPage = () => {
  const { cityId } = useParams(); // Get city name from URL
  const navigate = useNavigate();
  const city = useSelector((state) => state.cityReducer);
  const dispatch = useDispatch();
  const [coverImageUrl, setCoverImageUrl] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

  useEffect(() => {
    const fetchClubs = async () => {
      try {
        const response = await api.getClubInCity(cityId)
        dispatch(updateClubsInCity(response.data))
      } catch (error) {
        console.error("Error fetching clubs:", error);
      }
    };
    const fetchImagesData = async () => {
      try {
          const response = await api.getClubImages(cityId);
          setCoverImageUrl(response.data);
      } catch (error) {
          console.error('Failed to fetch images data:', error);
      }
    };
    fetchClubs();
    fetchImagesData();
  }, [cityId]); 

  return (
    <div style={{marginTop:50}}>
      <NavBar />
      <div style={{ position: 'relative', textAlign: 'center', color: 'white', marginTop:10, width: "100%" }}>
          <img src={city.cover_image} alt="Banner" style={{ 
            width: '100%', // Keeps image aspect ratio
            maxWidth: '100vw', // Limits the image width on larger screens
            display: 'block',
            boxShadow: '0px 2px 8px rgba(0,0,0,0.3)' // Adds shadow to images for depth
           }} />
          {/* <h1 className="neon-purple" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          Discover nightlife in {city.cityName}
          </h1> */}
      </div>
      <div style={{ color: 'white' }}>
        <Box sx={{ flexGrow: 1, padding: 3 }}>
          <Grid container spacing={3}>
            {city.clubs.map((club, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardActionArea onClick={() => navigate(`/clubs/${club._id}`)}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={coverImageUrl[index]}
                      alt={club.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {club.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {club.desc}
                        <br /> <br />
                        {club.address}
                        <br />
                        {club.phone}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>  
      </div>
      <Footer/>
    </div>
  );
}

export default CityPage;