import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Typography, Container, FormControl, InputLabel, MenuItem, Select, Box, Tabs, Tab } from '@mui/material';
import NavBar from "../NavBar/NavBar";
// Assume these are your components for profile info and past reservations
import ProfileInfo from './ProfileInfo';
import PastReservations from './PastReservations';
import { format } from 'date-fns'; // Import format from date-fns for date formatting
import * as api from '../../api/index.js';
import { fillClubAdmin } from '../../reducers/admin.js';
import './styles.css'; 
import ReservationsAdmin from './ReservationsAdmin.js';
import ClubProfile from './ClubProfile.js';
import ImageUploadSection from './ImageUploadSection.js';
import SeatTypeSection from './SeatTypeSection.js';
import PriceAvailabilitySection from './PriceAvailabilitySection.js';
import ImageUploadSectionUser from './ImageUploadSectionUser.js';
import Footer from '../Footer/Footer.js';
import PastTransactions from './PastTransactions.js';
import TransactionsAdmin from './TransactionsAdmin.js';
import Discounts from './Discounts.js';

const Profile = () => {
    const user = useSelector((state) => state.authReducer.authData);
    const isAdmin = user?.isAdmin;
    const [section, setSection] = useState('profileInfo');
    const clubAdmin = useSelector((state)=>state.adminReducer.clubAdmin)
    const dispatch = useDispatch();

    const memberSince = (user) => {
        return user?.createdAt ? format(new Date(user.createdAt), 'MMMM yyyy') : 'N/A';
    }

    useEffect(() => {
        const fetchClub = async () => {
            try {
                const club = await api.getClubAdmin(user?._id);
                dispatch(fillClubAdmin(club))
            } catch (error) {
                console.log(error)
            }
        }
        if (user && isAdmin) {
            fetchClub()
        }
    }, [user]);

    const handleSectionChange = (event) => {
        setSection(event.target.value);
    };

    if (!user) {
        return (
            <div>
                <NavBar />
                <Box display="flex" flexDirection="column" alignItems="center" >
                    <Typography variant="h5" sx={{marginTop: 8, color:"white" }}>
                        Please log in to continue
                    </Typography>
                </Box>
            </div>
        );
    }

    const getInitials = (firstName) => {
        return firstName ? firstName[0].toUpperCase() : '';
    };

    return (
        <div>
            <NavBar />
            <Container sx={{ marginTop: 16 }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Avatar sx={{ bgcolor: '#9c27b0', width: 56, height: 56 }}>
                            {getInitials(user.firstName)}
                    </Avatar>
                    {(isAdmin && clubAdmin)?  
                    <Typography className="neon-purple" variant="h3" sx={{ mt: 2, color: "white" }}>
                        {clubAdmin?.name} 
                    </Typography> :
                    <Typography variant="h4" sx={{ mt: 2, color: "white" }}>
                        {user.firstName} {user.lastName}
                    </Typography>}
                    {isAdmin? <Typography variant="h6" sx={{ mb:2, color: "white"  }}>
                        Member Since {memberSince(clubAdmin)}
                    </Typography> : <Typography variant="h6" sx={{ mb:2, color: "white"  }}>
                        Member Since {memberSince(user)}
                    </Typography>}
                    {!isAdmin && <Typography variant="h5" sx={{ mb: 2, color: "#9c27b0" }}>
                        Nightly Points: {user?.points ? user?.points : 0}
                    </Typography>}
                    {clubAdmin? 
                    <>
                    <FormControl fullWidth sx={{
                      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'purple', // Change border color to purple on hover
                        },
                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'purple', // Keep border color purple when focused
                        },         
                    }}>
                        <Select
                        id="section-simple-select"
                        value={section}
                        onChange={handleSectionChange}
                        sx={{ 
                            color: 'black',
                            '& .MuiInputBase-input': {
                                color: 'white', // Text color
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0', // Border color
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#9c27b0', // Border color on hover
                              },
                              '& .MuiInputLabel-root': { // Label color
                                color: '#9c27b0',
                              },
                        }}
                        >
                        <MenuItem value="profileInfo">Admin Profile</MenuItem>
                        <MenuItem value="clubInfo">Club Profile</MenuItem>
                        <MenuItem value="profileImages">Profile Images</MenuItem>
                        <MenuItem value="images">Your Media</MenuItem>
                        <MenuItem value="seats">Seat Type Selection</MenuItem>
                        <MenuItem value="discounts">Discounts</MenuItem>
                        <MenuItem value="priceAvailability">Price/Availability</MenuItem>
                        <MenuItem value="pastTransactions">Past Transactions</MenuItem>
                        {/* <MenuItem value="pastReservations">Your Reservations</MenuItem> */}
                        </Select>
                    </FormControl>
                    {section === 'profileInfo' && <ProfileInfo />}
                    {section === 'clubInfo' && <ClubProfile />}
                    {section === 'profileImages' && <ImageUploadSection />}
                    {section === 'images' && <ImageUploadSectionUser />}
                    {section === 'seats' && <SeatTypeSection />}
                    {section === 'priceAvailability' && <PriceAvailabilitySection />} 
                    {section === 'discounts' && <Discounts />}
                     {section === 'pastTransactions' && <TransactionsAdmin />} 
                    {/* {section === 'pastReservations' && <ReservationsAdmin />}  */}
                    
                    </>
                    :   <>
                        <FormControl fullWidth>
                        <Select
                            value={section}
                            onChange={handleSectionChange}
                            sx={{ 
                                color: 'black',
                                '& .MuiInputBase-input': {
                                    color: 'white', // Text color
                                  },
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#9c27b0', // Border color
                                  },
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#9c27b0', // Border color on hover
                                  },
                                  '& .MuiInputLabel-root': { // Label color
                                    color: '#9c27b0',
                                  },
                            }}
                            >
                            <MenuItem value="profileInfo">Profile Info</MenuItem>
                            <MenuItem value="images">Your Media</MenuItem>
                            <MenuItem value="pastTransactions">Your Bill Transactions</MenuItem>
                            <MenuItem value="pastReservations">Your Reservations</MenuItem>
                        </Select>
                        </FormControl>
                        {section === 'profileInfo' && <ProfileInfo />}
                        {section === 'images' && <ImageUploadSectionUser />}
                        {section === 'pastTransactions' && <PastTransactions />}
                        {section === 'pastReservations' && <PastReservations />}
                    </>
                    }
                </Box>
            </Container>
            <Footer />
        </div>
    );
}

export default Profile;
