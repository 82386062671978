import React from 'react';
import { Container, Typography, Box, Grid, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import NavBar from '../NavBar/NavBar';
import Footer from './Footer';
import aditPic from '../../images/aditPic.png';
import './styles.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Icon for bullet points

const PrivacyPolicy = () => {
  return (
    <div style={{ background: 'linear-gradient(280deg, #9c27b0 0%, #000000 30%)', minHeight: '100vh', color: "white" }}>
        <NavBar/>
        <Container maxWidth="lg" sx={{ py: 8, mt:9 }}>
            <Typography variant="h2" gutterBottom align="center" className='neon-purple'>
                Privacy Policy
            </Typography>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography paragraph>
                Welcome to Nightly, your premier platform for exploring, booking, and experiencing the vibrant nightlife around you. This Privacy Policy outlines how we collect, use, protect, and share information about our users ("you") as you use the Nightly app and services (collectively, the "Service").
                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Information Collection
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple'/>
                        </ListItemIcon>
                        <ListItemText primary="Personal Information: When you create an account, we collect personal information such as your name, email address, and phone number. This information helps us personalize your experience and communicate important booking details."/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple' />
                        </ListItemIcon>
                        <ListItemText primary="Location Data: To provide tailored event recommendations and facilitate bookings, we may collect precise location data from your device, subject to your permissions." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple' />
                        </ListItemIcon>
                        <ListItemText primary="Usage Data: We collect information about your interactions with our Service, such as bookings made, and feedback provided. This data helps us improve our offerings and user experience." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple' />
                        </ListItemIcon>
                        <ListItemText primary="Content You Provide: Nightly allows you to upload images and reviews. This content may include personal information and will be visible to other users as part of our community-driven experience." />
                    </ListItem>
                </List>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                 How We Use Your Information
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple'/>
                        </ListItemIcon>
                        <ListItemText primary="To Provide Our Service: We use your information to fulfill reservations, provide customer support, and communicate with you about your bookings and our services."/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple' />
                        </ListItemIcon>
                        <ListItemText primary="Improvement and Development: We analyze usage data to improve our app, develop new features, and ensure the security of our Service." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircleOutlineIcon className='neon-purple' />
                        </ListItemIcon>
                        <ListItemText primary="Marketing: With your consent, we may use your information to send you promotional messages and offers related to Nightly." />
                    </ListItem>
                </List>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Data Protection
                </Typography>
                <Typography paragraph>
                    We are committed to protecting the data of the Nightly community. Our team works tirelessly to protect your information from unauthorized access, alteration, disclosure, or destruction. We've implemented several physical and electronic measures to keep your information safe.
                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Your Rights and Choices
                </Typography>
                <Typography paragraph>
                    You have rights over your personal data, including the ability to access, correct, or delete your information. Nightly provides tools for you to do this directly within the service or by contacting us directly to do it on your behalf.
                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Changes to This Policy
                </Typography>
                <Typography paragraph>
                    Our Privacy Policy may change from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice.
                </Typography>
            </Box>

            <Box my={4} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.08)', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom className='neon-purple'>
                    Contact Us
                </Typography>
                <Typography paragraph>
                    If you have any questions about this Privacy Policy, please contact us at nightly@callidoratechnology.com.
                </Typography>
            </Box>
        </Container>
        <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
