import { createSlice, configureStore } from '@reduxjs/toolkit'

const seatSlice = createSlice({
    name: 'seat',
    initialState : {
        seats: [],
    },
    reducers: {
        updateSeat: (state, action) => {
            state.seats = action.payload;
        },
        editSeat: (state, action) => {
            const index = state.seats.findIndex(seat => seat._id === action.payload._id);
            if (index !== -1) {
              // Seat exists, update it
              state.seats[index] = action.payload;
            } else {
              // New seat, add it to the state
              state.seats.push(action.payload);
            }
        },
        removeSeat: (state, action) => {
            state.seats = state.seats.filter(seat => seat._id !== action.payload);
        },
    }
})

export const { updateSeat, editSeat, removeSeat} = seatSlice.actions
export default seatSlice.reducer;